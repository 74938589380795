/* shadow */
/* sizing */
/* spacing */
.semi-image {
  border-radius: var(--semi-border-radius-small);
  position: relative;
  display: inline-block;
  overflow: hidden;
}
.semi-image-img {
  vertical-align: middle;
  border-radius: inherit;
  user-select: none;
}
.semi-image-img-preview {
  cursor: zoom-in;
}
.semi-image-img-error {
  opacity: 0;
}
.semi-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.semi-image-status {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--semi-border-radius-small);
  background-color: var(--semi-color-fill-0);
  color: var(--semi-color-disabled-text);
}

.semi-image-preview {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1070;
  background-color: var(--semi-color-overlay-bg);
  transition: opacity 500ms;
  overflow: hidden;
}
.semi-image-preview-popup {
  position: absolute;
}
.semi-image-preview .semi-image-preview-hide {
  opacity: 0;
}
.semi-image-preview-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.75);
  cursor: pointer;
  color: var(--semi-color-white);
}
.semi-image-preview-prev {
  left: 24px;
}
.semi-image-preview-next {
  right: 24px;
}
.semi-image-preview-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--semi-color-white);
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  z-index: 1;
}
.semi-image-preview-header-title {
  flex: 1;
}
.semi-image-preview-header-close {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.semi-image-preview-header-close:hover {
  background-color: rgba(0, 0, 0, 0.75);
}
.semi-image-preview-footer {
  display: flex;
  align-items: center;
}
.semi-image-preview-footer-content {
  padding: 0 16px;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 6px;
  height: 48px;
}
.semi-image-preview-footer-wrapper {
  position: absolute;
  left: 50%;
  bottom: 16px;
  transform: translateX(-50%);
}
.semi-image-preview-footer-page {
  user-select: none;
  color: var(--semi-color-white);
  font-size: 16px;
  line-height: 22px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0 12px;
}
.semi-image-preview-footer .semi-icon {
  color: var(--semi-color-white);
  cursor: pointer;
}
.semi-image-preview-footer-gap {
  margin-left: 16px;
}
.semi-image-preview-footer .semi-slider {
  width: 132px;
  padding: 0 16px;
}
.semi-image-preview-footer .semi-slider .semi-slider-rail {
  color: var(--semi-color-white);
  height: 2px;
}
.semi-image-preview-footer .semi-slider .semi-slider-track {
  height: 2px;
}
.semi-image-preview-footer .semi-slider .semi-slider-handle {
  width: 16px;
  height: 16px;
  margin-top: 8px;
  box-sizing: border-box;
}
.semi-image-preview-footer .semi-divider {
  background: rgba(255, 255, 255, 0.5);
  margin: 0 16px;
}
.semi-image-preview-footer .semi-image-preview-footer-disabled {
  color: rgba(249, 249, 249, 0.35);
  cursor: default;
  pointer-events: none;
}
.semi-image-preview-image {
  position: relative;
  height: 100%;
}
.semi-image-preview-image-img {
  position: absolute;
  transform: scale3d(1, 1, 1) var(--semi-transform-rotate-none);
  transition: transform 300ms 0s;
  z-index: 0;
  user-select: none;
}
.semi-image-preview-image-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.semi-image-preview-image-spin .semi-spin-wrapper {
  color: #ccc;
}
@keyframes spinner {
  to {
    transform: var(--semi-transform_rotate-clockwise360deg);
  }
}

img[src=""], img:not([src]) {
  opacity: 0;
}

.semi-rtl .semi-image-preview,
.semi-portal-rtl .semi-image-preview {
  direction: rtl;
}
.semi-rtl .semi-image-preview-group,
.semi-portal-rtl .semi-image-preview-group {
  direction: rtl;
}
.semi-rtl .semi-image-preview-prev,
.semi-portal-rtl .semi-image-preview-prev {
  right: 24px;
  left: auto;
  transform: var(--semi-transform_rotate-clockwise180deg);
}
.semi-rtl .semi-image-preview-next,
.semi-portal-rtl .semi-image-preview-next {
  left: 24px;
  right: auto;
  transform: var(--semi-transform_rotate-clockwise180deg);
}
.semi-rtl .semi-image-preview-footer-page,
.semi-portal-rtl .semi-image-preview-footer-page {
  display: flex;
  direction: rtl;
}
.semi-rtl .semi-image-preview-footer-gap,
.semi-portal-rtl .semi-image-preview-footer-gap {
  margin-right: 16px;
  margin-left: 0;
}
.semi-rtl .semi-image-preview-footer .semi-icon-chevron_left,
.semi-portal-rtl .semi-image-preview-footer .semi-icon-chevron_left {
  transform: var(--semi-transform_rotate-clockwise180deg);
}
.semi-rtl .semi-image-preview-footer .semi-icon-chevron_right,
.semi-portal-rtl .semi-image-preview-footer .semi-icon-chevron_right {
  transform: var(--semi-transform_rotate-clockwise180deg);
}