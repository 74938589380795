/* shadow */
/* sizing */
/* spacing */
.semi-breadcrumb {
  overflow: hidden;
}
.semi-breadcrumb-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.semi-breadcrumb-wrapper-loose {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
}
.semi-breadcrumb-wrapper-compact {
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
}
.semi-breadcrumb-item-wrap {
  display: inline-flex;
  align-items: center;
  margin: 4px 0px;
  margin-right: 4px;
}
.semi-breadcrumb-item {
  display: inline;
  margin-right: 4px;
  color: var(--semi-color-text-2);
  font-weight: 400;
}
.semi-breadcrumb-item .semi-typography {
  color: inherit;
}
.semi-breadcrumb-item-title-inline {
  display: inline-flex;
}
.semi-breadcrumb-item-active {
  color: var(--semi-color-text-0);
  font-weight: 600;
}
.semi-breadcrumb-item-active:hover, .semi-breadcrumb-item-active:active {
  border: none;
  margin-bottom: 0px;
  color: var(--semi-color-text-0);
  cursor: default;
}
.semi-breadcrumb-item-icon {
  margin-bottom: -1px;
}
.semi-breadcrumb-item-more svg {
  vertical-align: middle;
}
.semi-breadcrumb-item-link {
  display: inline-flex;
  align-items: center;
  column-gap: 4px;
  text-decoration: inherit;
  transition: color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
  transform: scale(var(--semi-transform_scale-none));
}
.semi-breadcrumb-item-link:hover {
  color: var(--semi-color-link);
  cursor: pointer;
}
.semi-breadcrumb-item-link:active {
  color: var(--semi-color-link-hover);
  cursor: pointer;
}
.semi-breadcrumb-collapse {
  display: inline-flex;
  flex-shrink: 0;
}
.semi-breadcrumb-separator {
  display: flex;
  color: var(--semi-color-text-2);
}
.semi-breadcrumb-restItem {
  color: var(--semi-color-text-2);
  margin-right: 8px;
}

.semi-rtl .semi-breadcrumb-wrapper,
.semi-portal-rtl .semi-breadcrumb-wrapper {
  direction: rtl;
}
.semi-rtl .semi-breadcrumb-item-wrap,
.semi-portal-rtl .semi-breadcrumb-item-wrap {
  margin-right: 0;
  margin-left: 4px;
}
.semi-rtl .semi-breadcrumb-item,
.semi-portal-rtl .semi-breadcrumb-item {
  margin-right: 0;
  margin-left: 4px;
}
.semi-rtl .semi-breadcrumb-restItem,
.semi-portal-rtl .semi-breadcrumb-restItem {
  margin-right: 0;
  margin-left: 8px;
}
.semi-rtl .semi-breadcrumb-item-icon + .semi-breadcrumb-item-title,
.semi-portal-rtl .semi-breadcrumb-item-icon + .semi-breadcrumb-item-title {
  margin-left: 0;
  margin-right: 4px;
  display: inline-block;
}