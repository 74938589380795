/* shadow */
/* sizing */
/* spacing */
.semi-table-panel-operation {
  background-color: var(--semi-color-primary);
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
  color: var(--semi-color-text-2);
}
.semi-table-panel-operation-right, .semi-table-panel-operation-left {
  display: flex;
  justify-content: space-between;
}
.semi-table-panel-operation-selected {
  color: var(--semi-color-primary-light-active);
}

.semi-table-pagination-info {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
}
.semi-table-pagination-outer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.semi-table {
  width: 100%;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0;
  font-size: inherit;
  display: table;
}
.semi-table-wrapper {
  zoom: 1;
  position: relative;
  clear: both;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--semi-color-text-0);
  width: 100%;
}
.semi-table-wrapper[data-column-fixed=true] {
  z-index: 1;
}
.semi-table-wrapper-ltr {
  direction: ltr;
}
.semi-table-wrapper-ltr .semi-spin {
  direction: ltr;
}
.semi-table-middle .semi-table-tbody > .semi-table-row > .semi-table-row-cell {
  padding-top: 12px;
  padding-bottom: 12px;
}
.semi-table-small .semi-table-tbody > .semi-table-row > .semi-table-row-cell {
  padding-top: 8px;
  padding-bottom: 8px;
}
.semi-table-title {
  position: relative;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 0;
  padding-right: 0;
}
.semi-table-container {
  position: relative;
}
.semi-table-header {
  overflow: hidden;
  scrollbar-base-color: transparent;
}
.semi-table-header::-webkit-scrollbar {
  background-color: transparent;
  border-bottom: 2px solid var(--semi-color-border);
}
.semi-table-header-sticky {
  position: sticky;
  z-index: 102;
}
.semi-table-header-sticky .semi-table-thead > .semi-table-row > .semi-table-row-head {
  background-color: var(--semi-color-bg-1);
}
.semi-table-align-center .semi-table-operate-wrapper {
  justify-content: center;
}
.semi-table-align-right .semi-table-operate-wrapper {
  justify-content: flex-end;
}
.semi-table-operate-wrapper {
  display: flex;
  justify-content: flex-start;
}
.semi-table-body {
  overflow: auto;
  width: 100%;
  box-sizing: border-box;
}
.semi-table-colgroup {
  display: table-column-group;
}
.semi-table-colgroup .semi-table-col {
  display: table-column;
}
.semi-table-colgroup .semi-table-column-expand, .semi-table-colgroup .semi-table-column-selection {
  width: 48px;
}
.semi-table-thead > .semi-table-row > .semi-table-row-head {
  background-color: var(--semi-color-bg-1);
  color: var(--semi-color-text-2);
  font-weight: 600;
  text-align: left;
  border-bottom: 2px solid var(--semi-color-border);
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  vertical-align: middle;
  overflow-wrap: break-word;
  position: relative;
}
.semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-left, .semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-right {
  z-index: 101;
  position: sticky;
  background-color: var(--semi-color-bg-1);
}
.semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-left::before, .semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-right::before {
  background-color: var(--semi-color-bg-1);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: -1;
}
.semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-left-last {
  border-right: 1px solid var(--semi-color-border);
  box-shadow: 3px 0 0 0 var(--semi-color-shadow);
}
.semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-left-last.resizing {
  border-right: 2px solid var(--semi-color-primary);
}
.semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-left-last.resizing .react-resizable-handle:hover {
  background-color: unset;
}
.semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-right-first {
  border-left: 1px solid var(--semi-color-border);
  box-shadow: -3px 0 0 0 var(--semi-color-shadow);
}
.semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-right-first.resizing {
  border-right: 2px solid var(--semi-color-primary);
}
.semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-right-first.resizing .react-resizable-handle:hover {
  background-color: unset;
}
.semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-right-first[x-type=column-scrollbar] {
  box-shadow: none;
  border-left: transparent;
}
.semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-column-selection {
  text-align: center;
}
.semi-table-thead > .semi-table-row > .semi-table-row-head[colspan]:not([colspan="1"]) {
  text-align: center;
}
.semi-table-thead > .semi-table-row > .semi-table-row-head .semi-table-header-column {
  display: inline-flex;
  align-items: center;
}
.semi-table-thead > .semi-table-row > .semi-table-row-head-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.semi-table-thead > .semi-table-row > .semi-table-row-head-ellipsis .semi-table-row-head-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.semi-table-thead > .semi-table-row .react-resizable {
  position: relative;
  background-clip: padding-box;
}
.semi-table-thead > .semi-table-row .resizing {
  border-right: 2px solid var(--semi-color-primary);
}
.semi-table-thead > .semi-table-row .resizing .react-resizable-handle:hover {
  background-color: unset;
}
.semi-table-thead > .semi-table-row .react-resizable-handle {
  position: absolute;
  width: 9px;
  height: calc(100% - 4px * 2);
  background-color: var(--semi-color-border);
  bottom: 4px;
  right: -1px;
  cursor: col-resize;
  z-index: 0;
}
.semi-table-thead > .semi-table-row .react-resizable-handle:hover {
  background-color: var(--semi-color-primary);
}
.semi-table-tbody {
  display: table-row-group;
}
.semi-table-tbody > .semi-table-row {
  display: table-row;
  background-color: var(--semi-color-bg-1);
}
.semi-table-tbody > .semi-table-row:hover > .semi-table-row-cell {
  background-image: linear-gradient(0deg, var(--semi-color-fill-0), var(--semi-color-fill-0));
  background-color: var(--semi-color-bg-0);
}
.semi-table-tbody > .semi-table-row:hover > .semi-table-row-cell.semi-table-cell-fixed-left, .semi-table-tbody > .semi-table-row:hover > .semi-table-row-cell.semi-table-cell-fixed-right {
  background-image: linear-gradient(0deg, var(--semi-color-bg-1), var(--semi-color-bg-1));
}
.semi-table-tbody > .semi-table-row:hover > .semi-table-row-cell.semi-table-cell-fixed-left::before, .semi-table-tbody > .semi-table-row:hover > .semi-table-row-cell.semi-table-cell-fixed-right::before {
  background-color: var(--semi-color-fill-0);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: -1;
}
.semi-table-tbody > .semi-table-row > .semi-table-row-cell {
  display: table-cell;
  overflow-wrap: break-word;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--semi-color-border);
  padding: 16px;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
}
.semi-table-tbody > .semi-table-row > .semi-table-row-cell.resizing {
  border-right: 2px solid var(--semi-color-primary);
}
.semi-table-tbody > .semi-table-row > .semi-table-row-cell-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.semi-table-tbody > .semi-table-row.semi-table-row-expand > .semi-table-row-cell {
  background-color: var(--semi-color-fill-0);
}
.semi-table-tbody > .semi-table-row > .semi-table-cell-fixed-left, .semi-table-tbody > .semi-table-row > .semi-table-cell-fixed-right {
  z-index: 101;
  position: sticky;
  background-color: var(--semi-color-bg-1);
}
.semi-table-tbody > .semi-table-row > .semi-table-cell-fixed-left-last {
  border-right: 1px solid var(--semi-color-border);
  box-shadow: 3px 0 0 0 var(--semi-color-shadow);
}
.semi-table-tbody > .semi-table-row > .semi-table-cell-fixed-right-first {
  border-left: 1px solid var(--semi-color-border);
  box-shadow: -3px 0 0 0 var(--semi-color-shadow);
}
.semi-table-tbody > .semi-table-row > .semi-table-cell-fixed > * {
  transition: background-color var(--semi-transition_duration-none) var(--semi-transition_function-easeOut) 0ms;
}
.semi-table-tbody > .semi-table-row > * {
  transition: background-color var(--semi-transition_duration-none) var(--semi-transition_function-easeOut) 0ms;
}
.semi-table-tbody > .semi-table-row-section {
  display: table-row;
}
.semi-table-tbody > .semi-table-row-section > .semi-table-row-cell {
  background-color: rgba(var(--semi-grey-0), 1);
  border-bottom: 1px solid var(--semi-color-border);
}
.semi-table-tbody > .semi-table-row-section > .semi-table-row-cell:not(.semi-table-column-selection) {
  padding: 10px 16px;
}
.semi-table-tbody > .semi-table-row-section .semi-table-section-inner {
  display: inline-flex;
  align-items: center;
}
.semi-table-virtualized .semi-table-tbody {
  display: block;
}
.semi-table-virtualized .semi-table-tbody > .semi-table-row {
  display: flex;
}
.semi-table-virtualized .semi-table-tbody > .semi-table-row > .semi-table-row-cell {
  word-wrap: unset;
  word-break: unset;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
}
.semi-table-virtualized .semi-table-tbody > .semi-table-row-section > .semi-table-row-cell {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
}
.semi-table-virtualized .semi-table-tbody > .semi-table-row-expand > .semi-table-row-cell {
  padding: 0;
  overflow: unset;
}
.semi-table-footer {
  background-color: var(--semi-color-fill-0);
  padding: 16px;
  margin: 0;
  position: relative;
}
.semi-table .semi-table-selection-wrap {
  display: inline-flex;
  vertical-align: bottom;
}
.semi-table .semi-table-selection-disabled {
  cursor: not-allowed;
}
.semi-table .semi-table-selection-disabled > .semi-checkbox {
  pointer-events: none;
}
.semi-table .semi-table-column-hidden {
  display: none;
}
.semi-table .semi-table-column-selection {
  text-align: center;
}
.semi-table .semi-table-column-selection .semi-checkbox-inner-display .semi-icon {
  left: 0;
  top: 0;
}
.semi-table .semi-table-column-expand .semi-table-expand-icon {
  transform: translateY(2px);
}
.semi-table .semi-table-column-expand .semi-table-expand-icon:last-child {
  margin-right: 0;
}
.semi-table .semi-table-column-sorter {
  margin-left: 4px;
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  text-align: center;
}
.semi-table .semi-table-column-sorter-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}
.semi-table .semi-table-column-sorter-up, .semi-table .semi-table-column-sorter-down {
  height: 0;
  display: block;
  color: var(--semi-color-text-2);
}
.semi-table .semi-table-column-sorter-up:hover .anticon, .semi-table .semi-table-column-sorter-down:hover .anticon {
  color: var(--semi-color-text-2);
}
.semi-table .semi-table-column-sorter-up svg, .semi-table .semi-table-column-sorter-down svg {
  width: 16px;
  height: 16px;
}
.semi-table .semi-table-column-sorter-up.on .semi-icon-caretup,
.semi-table .semi-table-column-sorter-up.on .semi-icon-caretdown, .semi-table .semi-table-column-sorter-down.on .semi-icon-caretup,
.semi-table .semi-table-column-sorter-down.on .semi-icon-caretdown {
  color: var(--semi-color-primary);
}
.semi-table .semi-table-column-filter {
  margin-left: 4px;
  display: inline-flex;
  cursor: pointer;
  color: var(--semi-color-text-2);
  align-items: center;
}
.semi-table .semi-table-column-filter svg {
  width: 16px;
  height: 16px;
}
.semi-table .semi-table-column-filter.on {
  color: var(--semi-color-primary);
}
.semi-table-bordered .semi-table-title {
  padding-left: 16px;
  padding-right: 16px;
  border-top: 1px solid var(--semi-color-border);
  border-right: 1px solid var(--semi-color-border);
  border-left: 1px solid var(--semi-color-border);
}
.semi-table-bordered .semi-table-container {
  border: 1px solid var(--semi-color-border);
  border-right: 0;
  border-bottom: 0;
}
.semi-table-bordered .semi-table-header::-webkit-scrollbar {
  border-right: 1px solid var(--semi-color-border);
}
.semi-table-bordered .semi-table-footer {
  border-left: 1px solid var(--semi-color-border);
  border-right: 1px solid var(--semi-color-border);
  border-bottom: 1px solid var(--semi-color-border);
}
.semi-table-bordered .semi-table-thead > .semi-table-row > .semi-table-row-head .react-resizable-handle {
  background-color: transparent;
}
.semi-table-bordered .semi-table-thead > .semi-table-row > .semi-table-row-head,
.semi-table-bordered .semi-table-tbody > .semi-table-row > .semi-table-row-cell {
  border-right: 1px solid var(--semi-color-border);
}
.semi-table-bordered .semi-table-placeholder {
  border-right: 1px solid var(--semi-color-border);
}
.semi-table-placeholder {
  position: sticky;
  left: 0px;
  z-index: 1;
  padding: 16px 12px;
  color: var(--semi-color-text-2);
  font-size: 14px;
  text-align: center;
  background: transparent;
  border-bottom: 1px solid var(--semi-color-border);
  border-radius: 0 0 4px 4px;
}
.semi-table-fixed {
  table-layout: fixed;
  min-width: 100%;
}
.semi-table-fixed > .semi-table-tbody > .semi-table-row-expand > .semi-table-row-cell > .semi-table-expand-inner, .semi-table-fixed > .semi-table-tbody > .semi-table-row-section > .semi-table-row-cell > .semi-table-section-inner {
  position: sticky;
  overflow: auto;
  left: 0;
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px;
  height: 100%;
  display: flex;
  align-items: center;
}
.semi-table-fixed-header table {
  table-layout: fixed;
}
.semi-table-scroll-position-left .semi-table-tbody > .semi-table-row > .semi-table-cell-fixed-left-last,
.semi-table-scroll-position-left .semi-table-thead > .semi-table-row > .semi-table-cell-fixed-left-last {
  box-shadow: none;
}
.semi-table-scroll-position-right .semi-table-tbody > .semi-table-row > .semi-table-cell-fixed-right-first,
.semi-table-scroll-position-right .semi-table-thead > .semi-table-row > .semi-table-cell-fixed-right-first {
  box-shadow: none;
}
.semi-table-pagination-outer {
  color: var(--semi-color-text-2);
  min-height: 60px;
}

.semi-table-expand-icon {
  color: var(--semi-color-text-2);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  user-select: none;
  background: transparent;
  position: relative;
  margin-right: 8px;
}
.semi-table-expand-icon-cell {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.semi-table-expand-icon .semi-table-expandedIcon-show {
  transition: transform 150ms cubic-bezier(0.62, 0.05, 0.36, 0.95);
  transform: rotate(90deg);
}
.semi-table-expand-icon .semi-table-expandedIcon-hide {
  transition: transform 150ms cubic-bezier(0.62, 0.05, 0.36, 0.95);
  transform: rotate(0deg);
}

.semi-table-wrapper-rtl .semi-table {
  direction: rtl;
  text-align: right;
}
.semi-table-wrapper-rtl .semi-table-thead > .semi-table-row > .semi-table-row-head {
  text-align: right;
}
.semi-table-wrapper-rtl .semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-left-last {
  border-right: 0;
  border-left: 1px solid var(--semi-color-border);
}
.semi-table-wrapper-rtl .semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-left-last.resizing {
  border-left: 2px solid var(--semi-color-primary);
}
.semi-table-wrapper-rtl .semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-right-first {
  border-left: 0;
  border-right: 1px solid var(--semi-color-border);
}
.semi-table-wrapper-rtl .semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-right-first.resizing {
  border-left: 2px solid var(--semi-color-primary);
}
.semi-table-wrapper-rtl .semi-table-thead > .semi-table-row > .semi-table-row-head.semi-table-cell-fixed-right-first[x-type=column-scrollbar] {
  box-shadow: none;
  border-right: transparent;
}
.semi-table-wrapper-rtl .semi-table-thead > .semi-table-row .resizing {
  border-left: 2px solid var(--semi-color-primary);
}
.semi-table-wrapper-rtl .semi-table-thead > .semi-table-row .react-resizable-handle {
  right: auto;
  left: -1px;
}
.semi-table-wrapper-rtl .semi-table-tbody {
  display: table-row-group;
}
.semi-table-wrapper-rtl .semi-table-tbody > .semi-table-row > .semi-table-row-cell.resizing {
  border-right: 0;
  border-left: 2px solid var(--semi-color-primary);
}
.semi-table-wrapper-rtl .semi-table-tbody > .semi-table-row > .semi-table-cell-fixed-left-last {
  border-right: 0;
  border-left: 1px solid var(--semi-color-border);
}
.semi-table-wrapper-rtl .semi-table-tbody > .semi-table-row > .semi-table-cell-fixed-right-first {
  border-left: 0;
  border-right: 1px solid var(--semi-color-border);
}
.semi-table-wrapper-rtl .semi-table .semi-table-column-selection .semi-checkbox-inner-display .semi-icon {
  left: auto;
  right: 0;
}
.semi-table-wrapper-rtl .semi-table .semi-table-column-expand .semi-table-expand-icon {
  transform: scaleX(-1) translateY(2px);
}
.semi-table-wrapper-rtl .semi-table .semi-table-column-expand .semi-table-expand-icon:last-child {
  margin-right: auto;
  margin-left: 0;
}
.semi-table-wrapper-rtl .semi-table .semi-table-column-sorter {
  margin-left: auto;
  margin-right: 4px;
}
.semi-table-wrapper-rtl .semi-table .semi-table-column-filter {
  margin-left: auto;
  margin-right: 4px;
}
.semi-table-wrapper-rtl .semi-table-bordered .semi-table-container {
  border-left: 0;
  border-right: 1px solid var(--semi-color-border);
}
.semi-table-wrapper-rtl .semi-table-bordered .semi-table-thead > .semi-table-row > .semi-table-row-head,
.semi-table-wrapper-rtl .semi-table-bordered .semi-table-tbody > .semi-table-row > .semi-table-row-cell {
  border-right: 0;
  border-left: 1px solid var(--semi-color-border);
}
.semi-table-wrapper-rtl .semi-table-bordered .semi-table-placeholder {
  border-left: 1px solid var(--semi-color-border);
  border-right: 0;
}
.semi-table-wrapper-rtl .semi-table-bordered .semi-table-header::-webkit-scrollbar {
  border-right: 0;
  border-left: 1px solid var(--semi-color-border);
}
.semi-table-wrapper-rtl .semi-table-fixed > .semi-table-tbody > .semi-table-row-expand > .semi-table-row-cell > .semi-table-expand-inner, .semi-table-wrapper-rtl .semi-table-fixed > .semi-table-tbody > .semi-table-row-section > .semi-table-row-cell > .semi-table-section-inner {
  left: auto;
  right: 0;
  margin-right: -16px;
  margin-left: -16px;
  padding-right: 16px;
  padding-left: 16px;
}
.semi-table-wrapper-rtl .semi-table-scroll-position-left .semi-table-tbody > .semi-table-row > .semi-table-cell-fixed-left-last,
.semi-table-wrapper-rtl .semi-table-scroll-position-left .semi-table-thead > .semi-table-row > .semi-table-cell-fixed-left-last {
  box-shadow: 3px 0 0 0 var(--semi-color-shadow);
}
.semi-table-wrapper-rtl .semi-table-scroll-position-left .semi-table-tbody > .semi-table-row > .semi-table-cell-fixed-right-first,
.semi-table-wrapper-rtl .semi-table-scroll-position-left .semi-table-thead > .semi-table-row > .semi-table-cell-fixed-right-first {
  box-shadow: none;
}
.semi-table-wrapper-rtl .semi-table-scroll-position-right .semi-table-tbody > .semi-table-row > .semi-table-cell-fixed-left-last,
.semi-table-wrapper-rtl .semi-table-scroll-position-right .semi-table-thead > .semi-table-row > .semi-table-cell-fixed-left-last {
  box-shadow: none;
}
.semi-table-wrapper-rtl .semi-table-scroll-position-right .semi-table-tbody > .semi-table-row > .semi-table-cell-fixed-right-first,
.semi-table-wrapper-rtl .semi-table-scroll-position-right .semi-table-thead > .semi-table-row > .semi-table-cell-fixed-right-first {
  box-shadow: -3px 0 0 0 var(--semi-color-shadow);
}
.semi-table-wrapper-rtl .semi-table-expand-icon {
  margin-right: auto;
  margin-left: 8px;
  transform: scaleX(-1) translateY(2px);
}
.semi-table-wrapper-rtl .semi-spin {
  direction: rtl;
}