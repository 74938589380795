/* shadow */
/* sizing */
/* spacing */
.semi-calendar-day ul,
.semi-calendar-day li,
.semi-calendar-week ul,
.semi-calendar-week li,
.semi-calendar-month ul,
.semi-calendar-month li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.semi-calendar-day-sticky-top,
.semi-calendar-week-sticky-top,
.semi-calendar-month-sticky-top {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--semi-color-bg-2);
  z-index: 20;
}
.semi-calendar-day .semi-calendar-weekend,
.semi-calendar-week .semi-calendar-weekend,
.semi-calendar-month .semi-calendar-weekend {
  background-color: var(--semi-color-fill-0);
}
.semi-calendar-day .semi-calendar-event-items,
.semi-calendar-week .semi-calendar-event-items,
.semi-calendar-month .semi-calendar-event-items {
  pointer-events: none;
}
.semi-calendar-day .semi-calendar-event-items > *,
.semi-calendar-week .semi-calendar-event-items > *,
.semi-calendar-month .semi-calendar-event-items > * {
  pointer-events: auto;
}
.semi-calendar-day .semi-calendar-event-items .semi-calendar-event-day,
.semi-calendar-week .semi-calendar-event-items .semi-calendar-event-day,
.semi-calendar-month .semi-calendar-event-items .semi-calendar-event-day {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 5;
  overflow: hidden;
}
.semi-calendar-day .semi-calendar-custom-container,
.semi-calendar-week .semi-calendar-custom-container,
.semi-calendar-month .semi-calendar-custom-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.semi-calendar-day,
.semi-calendar-week {
  overflow: auto;
}
.semi-calendar-day-sticky-left,
.semi-calendar-week-sticky-left {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  background-color: var(--semi-color-bg-2);
  z-index: 10;
}
.semi-calendar-day .semi-calendar-tag,
.semi-calendar-week .semi-calendar-tag {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  min-width: 70px;
  color: var(--semi-color-text-2);
}
.semi-calendar-day-scroll,
.semi-calendar-week-scroll {
  display: flex;
  flex: 1 1 auto;
  position: relative;
}
.semi-calendar-day .semi-calendar-grid,
.semi-calendar-week .semi-calendar-grid {
  display: flex;
  align-items: flex-start;
  flex: 1 1 auto;
  position: relative;
}
.semi-calendar-day .semi-calendar-grid-skeleton,
.semi-calendar-week .semi-calendar-grid-skeleton {
  position: relative;
  box-sizing: border-box;
}
.semi-calendar-day .semi-calendar-grid-skeleton li,
.semi-calendar-week .semi-calendar-grid-skeleton li {
  height: 30px;
}
.semi-calendar-day .semi-calendar-grid-skeleton li:last-child, .semi-calendar-day .semi-calendar-grid-skeleton li:nth-last-child(2),
.semi-calendar-week .semi-calendar-grid-skeleton li:last-child,
.semi-calendar-week .semi-calendar-grid-skeleton li:nth-last-child(2) {
  height: 0;
}
.semi-calendar-day .semi-calendar-grid-skeleton-row-line::after,
.semi-calendar-week .semi-calendar-grid-skeleton-row-line::after {
  content: "";
  border-bottom: 1px solid var(--semi-color-border);
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
  pointer-events: none;
}
.semi-calendar-day .semi-calendar-grid-skeleton-row-line:first-child::after,
.semi-calendar-week .semi-calendar-grid-skeleton-row-line:first-child::after {
  border: 0;
}
.semi-calendar-day .semi-calendar-grid-content,
.semi-calendar-week .semi-calendar-grid-content {
  flex: 1 0 auto;
  min-width: 130px;
  height: 100%;
}
.semi-calendar-day .semi-calendar-grid-curr-circle,
.semi-calendar-week .semi-calendar-grid-curr-circle {
  background: var(--semi-color-danger);
  border-radius: var(--semi-border-radius-circle);
  content: "";
  position: absolute;
  height: 8px;
  width: 8px;
  margin-top: -4px;
  z-index: 8;
}
.semi-calendar-day .semi-calendar-grid-curr-line,
.semi-calendar-week .semi-calendar-grid-curr-line {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 8;
  border-top: 1px solid var(--semi-color-danger);
  pointer-events: none;
}
.semi-calendar-day .semi-calendar-all-day,
.semi-calendar-week .semi-calendar-all-day {
  display: flex;
  flex: 1 1 auto;
  border-bottom: 1px solid var(--semi-color-border);
}
.semi-calendar-day .semi-calendar-all-day .semi-calendar-tag,
.semi-calendar-week .semi-calendar-all-day .semi-calendar-tag {
  text-align: right;
  padding-right: 8px;
}
.semi-calendar-day .semi-calendar-all-day .semi-calendar-tag span,
.semi-calendar-week .semi-calendar-all-day .semi-calendar-tag span {
  line-height: 26px;
}
.semi-calendar-day .semi-calendar-all-day-content,
.semi-calendar-week .semi-calendar-all-day-content {
  display: flex;
  flex: 1 0 auto;
  height: 100%;
  min-height: 26px;
  min-width: 130px;
}
.semi-calendar-day .semi-calendar-all-day .semi-calendar-event-items,
.semi-calendar-week .semi-calendar-all-day .semi-calendar-event-items {
  width: 100%;
}
.semi-calendar-day .semi-calendar-time,
.semi-calendar-week .semi-calendar-time {
  height: auto;
  display: flex;
  flex: none;
  align-items: flex-start;
  padding-right: 8px;
}
.semi-calendar-day .semi-calendar-time-item,
.semi-calendar-week .semi-calendar-time-item {
  position: relative;
  height: 60px;
  text-align: right;
}
.semi-calendar-day .semi-calendar-time-item span,
.semi-calendar-week .semi-calendar-time-item span {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: block;
  position: relative;
  top: -10px;
  color: var(--semi-color-text-2);
}
.semi-calendar-day .semi-calendar-time .semi-calendar-time-items,
.semi-calendar-week .semi-calendar-time .semi-calendar-time-items {
  position: relative;
  min-width: 70px;
  background-color: var(--semi-color-bg-2);
  box-sizing: border-box;
  margin-left: auto;
}

.semi-calendar-week .semi-calendar-today .semi-calendar-today-date,
.semi-calendar-month .semi-calendar-today .semi-calendar-today-date {
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: var(--semi-border-radius-circle);
  background-color: var(--semi-color-primary);
  color: var(--semi-color-bg-1);
}

.semi-calendar-day-scroll-wrapper {
  height: calc(100% - 28px);
}

.semi-calendar-week {
  position: relative;
  display: grid;
}
.semi-calendar-week-scroll-wrapper {
  height: calc(100% - 55px);
}
.semi-calendar-week .semi-calendar-grid-skeleton-row-line::after {
  left: 0;
}
.semi-calendar-week .semi-calendar-grid-skeleton li {
  border-right: 1px solid var(--semi-color-border);
}
.semi-calendar-week .semi-calendar-grid:last-child .semi-calendar-grid-content li {
  border-right: 0;
}
.semi-calendar-week-header, .semi-calendar-week-grid-row {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--semi-color-text-2);
}
.semi-calendar-week-header {
  flex: 1 1 auto;
  border-bottom: 1px solid var(--semi-color-border);
}
.semi-calendar-week-grid {
  display: flex;
  flex: 1 0 auto;
}
.semi-calendar-week-grid-row {
  display: flex;
  flex: 1 0 auto;
}
.semi-calendar-week-grid-row li {
  display: flex;
  flex: 1 1 0;
  border-right: 1px solid transparent;
  height: auto;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}
.semi-calendar-week-grid-row li > span {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 26px;
}
.semi-calendar-week-grid-row li > span:last-child {
  padding: 0 4px;
}
.semi-calendar-week .semi-calendar-all-day {
  font-size: 26px;
}
.semi-calendar-week .semi-calendar-all-day-content {
  padding-left: 0;
  position: relative;
}
.semi-calendar-week .semi-calendar-all-day-content .semi-calendar-all-day-skeleton {
  display: flex;
  flex: 1 1 auto;
}
.semi-calendar-week .semi-calendar-all-day-content .semi-calendar-all-day-skeleton li {
  flex: 1 1 auto;
  min-width: 130px;
  box-sizing: border-box;
  border-right: 1px solid var(--semi-color-border);
}
.semi-calendar-week .semi-calendar-all-day-content .semi-calendar-all-day-skeleton li:last-child {
  border-right: 1px solid transparent;
}
.semi-calendar-week .semi-calendar-all-day .semi-calendar-event-items {
  position: absolute;
}
.semi-calendar-week .semi-calendar-all-day .semi-calendar-event-items .semi-calendar-event-allday {
  position: absolute;
}
.semi-calendar-week .semi-calendar-all-day .semi-calendar-event-items .semi-calendar-event-allday > * {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.semi-calendar-week .semi-calendar-week-tag {
  text-align: right;
  line-height: 26px;
}
.semi-calendar-week .semi-calendar-week-tag:first-child {
  padding-right: 8px;
}

.semi-calendar-month {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.semi-calendar-month-grid-wrapper {
  height: calc(100% - 27px);
}
.semi-calendar-month-week, .semi-calendar-month-skeleton {
  height: 100%;
}
.semi-calendar-month-header {
  display: flex;
  flex: 1 1 auto;
  align-items: flex-start;
  color: var(--semi-color-text-2);
}
.semi-calendar-month-grid {
  flex: 1 1 auto;
  box-sizing: border-box;
}
.semi-calendar-month-grid li {
  line-height: 26px;
}
.semi-calendar-month-grid-row, .semi-calendar-month-skeleton {
  display: flex;
  box-sizing: border-box;
}
.semi-calendar-month-grid-row li, .semi-calendar-month-skeleton li {
  flex: 1;
  white-space: nowrap;
  text-align: right;
  padding-right: 8px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--semi-color-border);
  color: var(--semi-color-text-2);
}
.semi-calendar-month-grid-row li span, .semi-calendar-month-skeleton li span {
  display: inline-block;
  text-align: right;
}
.semi-calendar-month-skeleton {
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
}
.semi-calendar-month-skeleton li {
  position: relative;
  overflow: hidden;
  border-right: 1px solid var(--semi-color-border);
  height: 100%;
  padding-top: 4px;
}
.semi-calendar-month-skeleton li span {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.semi-calendar-month-skeleton li:last-child {
  border-right: none;
}
.semi-calendar-month-skeleton .semi-calendar-month-same {
  color: var(--semi-color-text-0);
}
.semi-calendar-month-skeleton .semi-calendar-month-event-card-wrapper {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: block;
  position: absolute;
  right: 8px;
  padding-top: 2px;
  user-select: none;
}
.semi-calendar-month-skeleton .semi-calendar-month-event-card-wrapper:hover {
  cursor: pointer;
}
.semi-calendar-month .semi-calendar-event-items {
  position: absolute;
  top: 31px;
  right: 0;
  bottom: 20px;
  left: 0;
  overflow: hidden;
}
.semi-calendar-month-grid-col {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}
.semi-calendar-month-weekrow {
  flex: 1 1 auto;
  position: relative;
}
.semi-calendar-month-weekrow .semi-calendar-event-items .semi-calendar-event-month {
  position: absolute;
  font-size: 24px;
  height: 1em;
  overflow: hidden;
}
.semi-calendar-month-weekrow .semi-calendar-event-items .semi-calendar-event-month > * {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.semi-calendar-month-date {
  position: absolute;
  right: 4px;
  z-index: 3;
}

.semi-calendar-month-event-card {
  width: 220px;
}
.semi-calendar-month-event-card ul,
.semi-calendar-month-event-card li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.semi-calendar-month-event-card-close {
  margin-right: -4px;
}
.semi-calendar-month-event-card-content {
  padding: 12px 0;
}
.semi-calendar-month-event-card-header {
  display: flex;
  flex-direction: row;
  margin: 12px 20px;
}
.semi-calendar-month-event-card-header-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}
.semi-calendar-month-event-card-header-info-weekday {
  color: var(--semi-color-text-2);
}
.semi-calendar-month-event-card-header-info-date {
  font-size: 20px;
  line-height: 28px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-top: 4px;
}
.semi-calendar-month-event-card-body {
  padding: 0 16px;
}
.semi-calendar-month-event-card-body li {
  height: 24px;
}

.semi-rtl .semi-calendar-day,
.semi-rtl .semi-calendar-week,
.semi-rtl .semi-calendar-month,
.semi-portal-rtl .semi-calendar-day,
.semi-portal-rtl .semi-calendar-week,
.semi-portal-rtl .semi-calendar-month {
  direction: rtl;
}
.semi-rtl .semi-calendar-day-sticky-left,
.semi-rtl .semi-calendar-week-sticky-left,
.semi-portal-rtl .semi-calendar-day-sticky-left,
.semi-portal-rtl .semi-calendar-week-sticky-left {
  left: auto;
  right: 0;
}
.semi-rtl .semi-calendar-day .semi-calendar-all-day .semi-calendar-tag,
.semi-rtl .semi-calendar-week .semi-calendar-all-day .semi-calendar-tag,
.semi-portal-rtl .semi-calendar-day .semi-calendar-all-day .semi-calendar-tag,
.semi-portal-rtl .semi-calendar-week .semi-calendar-all-day .semi-calendar-tag {
  text-align: left;
  padding-right: 0;
  padding-left: 8px;
}
.semi-rtl .semi-calendar-day .semi-calendar-time,
.semi-rtl .semi-calendar-week .semi-calendar-time,
.semi-portal-rtl .semi-calendar-day .semi-calendar-time,
.semi-portal-rtl .semi-calendar-week .semi-calendar-time {
  padding-right: 0;
  padding-left: 8px;
}
.semi-rtl .semi-calendar-day .semi-calendar-time-item,
.semi-rtl .semi-calendar-week .semi-calendar-time-item,
.semi-portal-rtl .semi-calendar-day .semi-calendar-time-item,
.semi-portal-rtl .semi-calendar-week .semi-calendar-time-item {
  text-align: left;
}
.semi-rtl .semi-calendar-day .semi-calendar-time .semi-calendar-time-items,
.semi-rtl .semi-calendar-week .semi-calendar-time .semi-calendar-time-items,
.semi-portal-rtl .semi-calendar-day .semi-calendar-time .semi-calendar-time-items,
.semi-portal-rtl .semi-calendar-week .semi-calendar-time .semi-calendar-time-items {
  margin-left: 0;
  margin-right: auto;
}
.semi-rtl .semi-calendar-week .semi-calendar-grid-skeleton-row-line::after,
.semi-portal-rtl .semi-calendar-week .semi-calendar-grid-skeleton-row-line::after {
  left: 0;
  right: 0;
}
.semi-rtl .semi-calendar-week .semi-calendar-grid-skeleton li,
.semi-portal-rtl .semi-calendar-week .semi-calendar-grid-skeleton li {
  border-right: 0;
  border-left: 1px solid var(--semi-color-border);
}
.semi-rtl .semi-calendar-week .semi-calendar-grid:last-child .semi-calendar-grid-content li,
.semi-portal-rtl .semi-calendar-week .semi-calendar-grid:last-child .semi-calendar-grid-content li {
  border-left: 0;
}
.semi-rtl .semi-calendar-week .semi-calendar-all-day-content,
.semi-portal-rtl .semi-calendar-week .semi-calendar-all-day-content {
  padding-left: auto;
  padding-right: 0;
}
.semi-rtl .semi-calendar-week .semi-calendar-all-day-content .semi-calendar-all-day-skeleton li,
.semi-portal-rtl .semi-calendar-week .semi-calendar-all-day-content .semi-calendar-all-day-skeleton li {
  border-right: 0;
  border-left: 1px solid var(--semi-color-border);
}
.semi-rtl .semi-calendar-week .semi-calendar-all-day-content .semi-calendar-all-day-skeleton li:last-child,
.semi-portal-rtl .semi-calendar-week .semi-calendar-all-day-content .semi-calendar-all-day-skeleton li:last-child {
  border-left: 1px solid transparent;
}
.semi-rtl .semi-calendar-week .semi-rtl .semi-calendar-week-tag,
.semi-rtl .semi-calendar-week .semi-portal-rtl .semi-calendar-week-tag,
.semi-portal-rtl .semi-calendar-week .semi-rtl .semi-calendar-week-tag,
.semi-portal-rtl .semi-calendar-week .semi-portal-rtl .semi-calendar-week-tag {
  text-align: left;
}
.semi-rtl .semi-calendar-week .semi-rtl .semi-calendar-week-tag:first-child,
.semi-rtl .semi-calendar-week .semi-portal-rtl .semi-calendar-week-tag:first-child,
.semi-portal-rtl .semi-calendar-week .semi-rtl .semi-calendar-week-tag:first-child,
.semi-portal-rtl .semi-calendar-week .semi-portal-rtl .semi-calendar-week-tag:first-child {
  padding-right: auto;
  padding-left: 8px;
}
.semi-rtl .semi-calendar-month-grid-row li, .semi-rtl .semi-calendar-month-skeleton li,
.semi-portal-rtl .semi-calendar-month-grid-row li,
.semi-portal-rtl .semi-calendar-month-skeleton li {
  text-align: left;
  padding-right: auto;
  padding-left: 8px;
}
.semi-rtl .semi-calendar-month-grid-row li span, .semi-rtl .semi-calendar-month-skeleton li span,
.semi-portal-rtl .semi-calendar-month-grid-row li span,
.semi-portal-rtl .semi-calendar-month-skeleton li span {
  text-align: left;
}
.semi-rtl .semi-calendar-month-skeleton li,
.semi-portal-rtl .semi-calendar-month-skeleton li {
  border-right: 0;
  border-left: 1px solid var(--semi-color-border);
}
.semi-rtl .semi-calendar-month-skeleton li:last-child,
.semi-portal-rtl .semi-calendar-month-skeleton li:last-child {
  border-left: none;
}
.semi-rtl .semi-calendar-month-skeleton .semi-calendar-month-event-card-wrapper,
.semi-portal-rtl .semi-calendar-month-skeleton .semi-calendar-month-event-card-wrapper {
  right: auto;
  left: 8px;
}
.semi-rtl .semi-calendar-month-date,
.semi-portal-rtl .semi-calendar-month-date {
  right: auto;
  left: 4px;
}
.semi-rtl .semi-calendar-month-event-card-close,
.semi-portal-rtl .semi-calendar-month-event-card-close {
  margin-right: 0;
  margin-left: -4px;
}