/* shadow */
/* sizing */
/* spacing */
.semi-steps .semi-col {
  flex: 1;
}
.semi-steps-vertical .semi-row-flex {
  flex-direction: column;
}
.semi-steps .semi-steps-item {
  box-sizing: border-box;
  display: flex;
  height: 72px;
  position: relative;
  overflow: hidden;
  margin-right: 16px;
  border: 1px solid transparent;
  border-radius: var(--semi-border-radius-medium);
  padding: 12px 16px;
  transition: color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
  transform: scale(var(--semi-transform_scale-small));
  transition: background-color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
}
.semi-steps .semi-steps-item .semi-steps-item-title {
  position: relative;
  font-size: 18px;
  line-height: 24px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--semi-color-text-0);
  transition: color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
}
.semi-steps .semi-steps-item .semi-steps-item-description {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--semi-color-text-2);
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.semi-steps .semi-steps-item-process {
  background-color: var(--semi-color-primary-light-default);
}
.semi-steps .semi-steps-item-process .semi-steps-item-left:not(.semi-steps-item-icon) {
  background: var(--semi-color-primary);
}
.semi-steps .semi-steps-item-process .semi-steps-item-title,
.semi-steps .semi-steps-item-process .semi-steps-item-icon {
  color: var(--semi-color-primary);
}
.semi-steps .semi-steps-item-wait .semi-steps-item-left:not(.semi-steps-item-icon) {
  background: var(--semi-color-text-2);
}
.semi-steps .semi-steps-item-wait .semi-steps-item-icon {
  color: var(--semi-color-text-2);
}
.semi-steps .semi-steps-item-finish .semi-icon,
.semi-steps .semi-steps-item-finish .semi-steps-item-title {
  color: var(--semi-color-success);
}
.semi-steps .semi-steps-item-finish-hover:hover {
  background-color: var(--semi-color-fill-0);
}
.semi-steps .semi-steps-item-finish-hover:hover .semi-icon,
.semi-steps .semi-steps-item-finish-hover:hover .semi-steps-item-title {
  color: var(--semi-color-success-hover);
}
.semi-steps .semi-steps-item-finish:active {
  background-color: var(--semi-color-fill-1);
}
.semi-steps .semi-steps-item-finish:active .semi-icon,
.semi-steps .semi-steps-item-finish:active .semi-steps-item-title {
  color: var(--semi-color-success-active);
}
.semi-steps .semi-steps-item-error .semi-icon,
.semi-steps .semi-steps-item-error .semi-steps-item-title {
  color: var(--semi-color-danger);
}
.semi-steps .semi-steps-item-error-hover:hover {
  background: var(--semi-color-fill-0);
}
.semi-steps .semi-steps-item-error-hover:hover .semi-icon,
.semi-steps .semi-steps-item-error-hover:hover .semi-steps-item-title {
  color: var(--semi-color-danger-hover);
}
.semi-steps .semi-steps-item-error:active {
  background-color: var(--semi-color-fill-1);
}
.semi-steps .semi-steps-item-error:active .semi-icon,
.semi-steps .semi-steps-item-error:active .semi-steps-item-title {
  color: var(--semi-color-danger-active);
}
.semi-steps .semi-steps-item-warning .semi-steps-item-title,
.semi-steps .semi-steps-item-warning .semi-icon {
  color: var(--semi-color-warning);
}
.semi-steps .semi-steps-item-warning-hover:hover {
  background: var(--semi-color-fill-0);
}
.semi-steps .semi-steps-item-warning-hover:hover .semi-steps-item-title,
.semi-steps .semi-steps-item-warning-hover:hover .semi-icon {
  color: var(--semi-color-warning-hover);
}
.semi-steps .semi-steps-item-warning:active {
  background-color: var(--semi-color-fill-1);
}
.semi-steps .semi-steps-item-warning:active .semi-steps-item-title,
.semi-steps .semi-steps-item-warning:active .semi-icon {
  color: var(--semi-color-warning-active);
}
.semi-steps .semi-steps-item-clickable {
  cursor: pointer;
}
.semi-steps .semi-steps-item-left {
  width: 24px;
  height: 24px;
  line-height: 32px;
  text-align: center;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 28px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  flex-grow: 0;
}
.semi-steps .semi-steps-item-left.semi-steps-item-plain {
  color: rgba(var(--semi-white), 1);
}
.semi-steps .semi-steps-item-left-process {
  background: var(--semi-color-primary-light-default);
}
.semi-steps .semi-steps-item-content {
  margin-left: 16px;
  flex: 1;
  overflow: hidden;
}

.semi-steps-basic.semi-steps-horizontal {
  display: flex;
  flex-flow: row nowrap;
}
.semi-steps-basic.semi-steps-horizontal.semi-steps-hasline .semi-steps-item-title::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: var(--semi-color-fill-2);
}
.semi-steps-basic.semi-steps-horizontal .semi-steps-item {
  padding-left: 16px;
}
.semi-steps-basic.semi-steps-horizontal .semi-steps-item:first-child {
  padding-left: 0;
}
.semi-steps-basic.semi-steps-horizontal .semi-steps-item:last-child {
  flex: none;
}
.semi-steps-basic.semi-steps-horizontal .semi-steps-item:last-child .semi-steps-item-title {
  max-width: 100%;
  padding-right: 0;
}
.semi-steps-basic.semi-steps-horizontal .semi-steps-item:last-child .semi-steps-item-title::after {
  display: none;
}
.semi-steps-basic.semi-steps-horizontal .semi-steps-item-done .semi-steps-item-container .semi-steps-item-title::after {
  background: var(--semi-color-primary);
}
.semi-steps-basic.semi-steps-horizontal .semi-steps-item .semi-steps-item-content {
  flex: 1;
}
.semi-steps-basic.semi-steps-horizontal .semi-steps-item .semi-steps-item-description {
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--semi-color-text-2);
  width: 100%;
  max-width: 268px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.semi-steps-basic.semi-steps-horizontal .semi-steps-item .semi-steps-item-title {
  max-width: 80%;
}
.semi-steps-basic.semi-steps-horizontal .semi-steps-item .semi-steps-item-title .semi-steps-item-title-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
}
.semi-steps-basic.semi-steps-vertical {
  display: flex;
  flex-flow: column nowrap;
}
.semi-steps-basic.semi-steps-vertical.semi-steps-small .semi-steps-item .semi-steps-item-content {
  min-height: 40px;
}
.semi-steps-basic.semi-steps-vertical.semi-steps-hasline .semi-steps-item-icon::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  display: block;
  width: 1px;
  height: 9999px;
  background: var(--semi-color-fill-2);
}
.semi-steps-basic.semi-steps-vertical .semi-steps-item {
  padding-top: 9px;
}
.semi-steps-basic.semi-steps-vertical .semi-steps-item:first-child {
  padding-top: 0;
}
.semi-steps-basic.semi-steps-vertical .semi-steps-item:last-child .semi-steps-item-icon::after {
  display: none;
}
.semi-steps-basic.semi-steps-vertical .semi-steps-item-done .semi-steps-item-icon::after {
  background: var(--semi-color-primary);
}
.semi-steps-basic.semi-steps-vertical .semi-steps-item .semi-steps-item-content {
  min-height: 42px;
  padding-bottom: 22px;
}
.semi-steps-basic.semi-steps-vertical .semi-steps-item .semi-steps-item-icon {
  display: inline-flex;
  position: relative;
  padding-bottom: 9px;
}
.semi-steps-basic.semi-steps-vertical .semi-steps-item .semi-steps-item-description {
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--semi-color-text-2);
  width: 100%;
}
.semi-steps-basic.semi-steps-vertical .semi-steps-item .semi-steps-item-title {
  max-width: 100%;
}
.semi-steps-basic.semi-steps-vertical .semi-steps-item .semi-steps-item-title .semi-steps-item-title-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.semi-steps-basic .semi-steps-item {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  flex: 1;
  cursor: pointer;
  transition: color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none), background-color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
  transform: scale(var(--semi-transform_scale-small));
}
.semi-steps-basic .semi-steps-item-hover:hover .semi-steps-item-title {
  color: var(--semi-color-focus-border);
}
.semi-steps-basic .semi-steps-item-hover:hover .semi-steps-item-description {
  color: var(--semi-color-focus-border);
}
.semi-steps-basic .semi-steps-item .semi-steps-item-container {
  display: flex;
  align-items: flex-start;
}
.semi-steps-basic .semi-steps-item .semi-steps-item-left {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}
.semi-steps-basic .semi-steps-item .semi-steps-item-left .semi-steps-item-icon {
  display: flex;
  height: 24px;
  align-items: center;
}
.semi-steps-basic .semi-steps-item .semi-steps-item-left .semi-steps-item-number-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-size: 16px;
  line-height: 22px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  background: var(--semi-color-primary);
  border-radius: var(--semi-border-radius-circle);
  color: var(--semi-color-white);
  transition: color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
}
.semi-steps-basic .semi-steps-item .semi-steps-item-title {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 22px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 24px;
  font-weight: 600;
  color: var(--semi-color-text-0);
  vertical-align: top;
  padding-right: 16px;
  padding-bottom: 4px;
  transition: color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
}
.semi-steps-basic .semi-steps-item-finish .semi-steps-item-left .semi-steps-item-icon {
  color: var(--semi-color-primary);
}
.semi-steps-basic .semi-steps-item-finish .semi-steps-item-left .semi-steps-item-icon .semi-steps-item-number-icon {
  color: var(--semi-color-white);
}
.semi-steps-basic .semi-steps-item-wait .semi-steps-item-title {
  color: var(--semi-color-text-2);
}
.semi-steps-basic .semi-steps-item-wait .semi-steps-item-left .semi-steps-item-icon {
  color: var(--semi-color-text-2);
}
.semi-steps-basic .semi-steps-item-wait .semi-steps-item-left .semi-steps-item-icon .semi-steps-item-number-icon {
  background: var(--semi-color-fill-0);
  color: var(--semi-color-text-2);
}
.semi-steps-basic .semi-steps-item-wait-hover:hover .semi-steps-item-left .semi-steps-item-icon .semi-steps-item-number-icon {
  background: var(--semi-color-secondary-light-default);
  color: var(--semi-color-focus-border);
}
.semi-steps-basic .semi-steps-item-process .semi-steps-item-left .semi-steps-item-icon {
  color: var(--semi-color-primary);
}
.semi-steps-basic .semi-steps-item-process .semi-steps-item-left .semi-steps-item-icon .semi-steps-item-number-icon {
  color: var(--semi-color-white);
}
.semi-steps-basic .semi-steps-item-error .semi-steps-item-left .semi-steps-item-icon {
  color: var(--semi-color-danger);
}
.semi-steps-basic .semi-steps-item-error .semi-steps-item-left .semi-steps-item-icon .semi-steps-item-number-icon {
  color: var(--semi-color-primary);
}
.semi-steps-basic .semi-steps-item-warning .semi-steps-item-left .semi-steps-item-icon {
  color: var(--semi-color-warning);
}
.semi-steps-basic .semi-steps-item-warning .semi-steps-item-left .semi-steps-item-icon .semi-steps-item-number-icon {
  color: var(--semi-color-white);
}
.semi-steps-basic.semi-steps-small .semi-steps-item .semi-steps-item-title {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.semi-steps-basic.semi-steps-small .semi-steps-item .semi-steps-item-left .semi-steps-item-icon {
  height: 20px;
}
.semi-steps-basic.semi-steps-small .semi-steps-item .semi-steps-item-left .semi-steps-item-icon .semi-steps-item-number-icon {
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  width: 20px;
  height: 20px;
}

.semi-steps-nav {
  display: inline-flex;
  flex-flow: row nowrap;
}
.semi-steps-nav .semi-steps-item {
  box-sizing: border-box;
}
.semi-steps-nav .semi-steps-item:last-child {
  flex: none;
}
.semi-steps-nav .semi-steps-item:last-child .semi-steps-item-content {
  width: auto;
}
.semi-steps-nav .semi-steps-item .semi-steps-item-container {
  display: flex;
  align-items: center;
  color: var(--semi-color-text-2);
}
.semi-steps-nav .semi-steps-item .semi-steps-item-container .semi-steps-item-icon {
  display: inline-flex;
  flex: 1;
  justify-content: center;
  color: var(--semi-grey-3);
  min-width: 60px;
}
.semi-steps-nav .semi-steps-item .semi-steps-item-content {
  flex: 1;
  display: inline-block;
}
.semi-steps-nav .semi-steps-item .semi-steps-item-title {
  font-size: 16px;
  line-height: 22px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  max-width: 17em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
}
.semi-steps-nav .semi-steps-item-active .semi-steps-item-title {
  color: var(--semi-color-text-0);
  font-weight: 600;
}
.semi-steps-nav.semi-steps-small .semi-steps-item .semi-steps-item-title {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.semi-rtl .semi-steps,
.semi-portal-rtl .semi-steps {
  direction: rtl;
}
.semi-rtl .semi-steps-item,
.semi-portal-rtl .semi-steps-item {
  margin-right: auto;
  margin-left: 16px;
}
.semi-rtl .semi-steps-item-content,
.semi-portal-rtl .semi-steps-item-content {
  margin-left: auto;
  margin-right: 16px;
}
.semi-rtl .semi-steps-basic,
.semi-portal-rtl .semi-steps-basic {
  direction: rtl;
}
.semi-rtl .semi-steps-basic.semi-steps-horizontal .semi-steps-item .semi-steps-item-title,
.semi-portal-rtl .semi-steps-basic.semi-steps-horizontal .semi-steps-item .semi-steps-item-title {
  padding-left: 16px;
}
.semi-rtl .semi-steps-basic.semi-steps-horizontal .semi-steps-item .semi-steps-item-title::after,
.semi-portal-rtl .semi-steps-basic.semi-steps-horizontal .semi-steps-item .semi-steps-item-title::after {
  right: 100%;
}
.semi-rtl .semi-steps-basic.semi-steps-vertical .semi-steps-item,
.semi-portal-rtl .semi-steps-basic.semi-steps-vertical .semi-steps-item {
  margin: 0;
}
.semi-rtl .semi-steps-nav,
.semi-portal-rtl .semi-steps-nav {
  direction: rtl;
}