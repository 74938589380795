/* shadow */
/* sizing */
/* spacing */
.semi-transfer {
  background-color: var(--semi-color-bg-1);
  border: 1px solid var(--semi-color-border);
  border-radius: var(--semi-border-radius-medium);
  min-width: 402px;
  height: 400px;
  box-sizing: border-box;
  display: flex;
}
.semi-transfer-disabled .semi-transfer-header {
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
}
.semi-transfer-disabled .semi-transfer-item {
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
}
.semi-transfer-disabled .semi-transfer-item:hover {
  background-color: inherit;
}
.semi-transfer-disabled .semi-transfer-item:hover .semi-transfer-item-close-icon {
  visibility: hidden;
}
.semi-transfer-disabled .semi-transfer-item:active {
  background-color: inherit;
}
.semi-transfer-custom-panel {
  border: none;
  min-width: inherit;
  height: inherit;
}
.semi-transfer-header {
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: flex;
  align-items: center;
  height: 24px;
  margin-top: 12px;
  margin-right: 12px;
  margin-bottom: 8px;
  margin-left: 12px;
  color: var(--semi-color-text-2);
}
.semi-transfer-header-all {
  font-weight: 600;
  margin-left: 16px;
}
.semi-transfer-header .semi-button {
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.semi-transfer-item {
  min-height: 36px;
  box-sizing: border-box;
  padding-top: 8px;
  padding-right: 9px;
  padding-bottom: 8px;
  padding-left: 12px;
  user-select: none;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  color: var(--semi-color-text-1);
  cursor: pointer;
  transition: background-color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
}
.semi-transfer-item:hover {
  background-color: var(--semi-color-fill-0);
}
.semi-transfer-item:active {
  background-color: var(--semi-color-fill-1);
}
.semi-transfer-item-disabled {
  cursor: not-allowed;
}
.semi-transfer-item-disabled:hover {
  background-color: inherit;
}
.semi-transfer-item .semi-transfer-item-close-icon {
  font-size: 12px;
  cursor: pointer;
  visibility: hidden;
  color: var(--semi-color-text-2);
}
.semi-transfer-left {
  width: 50%;
  border-right: 1px solid var(--semi-color-border);
  display: flex;
  flex-direction: column;
}
.semi-transfer-left-list {
  overflow: auto;
  flex-grow: 1;
}
.semi-transfer-left-empty {
  height: 36px;
}
.semi-transfer-left > .semi-spin {
  width: 100%;
  flex-grow: 1;
}
.semi-transfer-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.semi-transfer-right-header {
  margin-top: 12px;
  margin-bottom: 0;
  height: 32px;
  flex-shrink: 0;
}
.semi-transfer-right-list {
  overflow: auto;
  flex-grow: 1;
}
.semi-transfer-right-item {
  color: var(--semi-color-text-0);
  cursor: auto;
}
.semi-transfer-right-item:hover .semi-transfer-item-close-icon {
  flex-shrink: 0;
  visibility: visible;
  cursor: pointer;
}
.semi-transfer-right-item:hover .semi-transfer-item-close-icon.semi-transfer-item-close-icon-disabled {
  visibility: hidden;
}
.semi-transfer-right-item-text {
  flex: 1;
  word-break: break-all;
}
.semi-transfer-right-item-drag-handler {
  margin-right: 4px;
  flex-shrink: 0;
}
.semi-transfer-right-item-drag-item-move {
  z-index: 2000;
}
.semi-transfer-right-empty {
  position: absolute;
  height: 100%;
}
.semi-transfer-empty {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--semi-color-text-2);
}
.semi-transfer-filter {
  margin-top: 12px;
  margin-right: 12px;
  margin-bottom: 0;
  margin-left: 12px;
}
.semi-transfer-group-title {
  color: var(--semi-color-text-2);
  padding-left: 12px;
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 28px;
  display: flex;
  align-items: center;
}

.semi-rtl .semi-transfer,
.semi-portal-rtl .semi-transfer {
  direction: rtl;
}
.semi-rtl .semi-transfer-header-all,
.semi-portal-rtl .semi-transfer-header-all {
  margin-left: 0;
  margin-right: 16px;
}
.semi-rtl .semi-transfer-item,
.semi-portal-rtl .semi-transfer-item {
  padding-left: 9px;
  padding-right: 12px;
}
.semi-rtl .semi-transfer-left,
.semi-portal-rtl .semi-transfer-left {
  border-right: 0;
  border-left: 1px solid var(--semi-color-border);
}
.semi-rtl .semi-transfer-group-title,
.semi-portal-rtl .semi-transfer-group-title {
  padding-left: 0;
  padding-right: 12px;
}