/* shadow */
/* sizing */
/* spacing */
.semi-tree-select {
  box-sizing: border-box;
  border-radius: var(--semi-border-radius-small);
  border: 1px solid transparent;
  min-width: 80px;
  min-height: 32px;
  line-height: 32px;
  font-weight: 400;
  background-color: var(--semi-color-fill-0);
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.semi-tree-select:hover {
  background-color: var(--semi-color-fill-1);
  border: 1px solid transparent;
}
.semi-tree-select:focus {
  border: 1px solid var(--semi-color-focus-border);
  background-color: var(--semi-color-fill-0);
  outline: 0;
}
.semi-tree-select:active {
  background-color: var(--semi-color-fill-2);
}
.semi-tree-select-focus {
  background-color: var(--semi-color-fill-0);
  border: 1px solid var(--semi-color-focus-border);
  outline: 0;
}
.semi-tree-select-focus:hover {
  background-color: var(--semi-color-fill-0);
  border: 1px solid var(--semi-color-focus-border);
  outline: 0;
}
.semi-tree-select-focus:active {
  background-color: var(--semi-color-fill-2);
}
.semi-tree-select-warning {
  background-color: var(--semi-color-warning-light-default);
  border-color: var(--semi-color-warning-light-default);
}
.semi-tree-select-warning:hover {
  background-color: var(--semi-color-warning-light-hover);
  border-color: var(--semi-color-warning-light-hover);
}
.semi-tree-select-warning.semi-tree-select-focus {
  background-color: var(--semi-color-warning-light-default);
  border-color: var(--semi-color-warning);
}
.semi-tree-select-warning:active {
  background-color: var(--semi-color-warning-light-active);
  border-color: var(--semi-color-warning-light-active);
}
.semi-tree-select-error {
  background-color: var(--semi-color-danger-light-default);
  border-color: var(--semi-color-danger-light-default);
}
.semi-tree-select-error:hover {
  background-color: var(--semi-color-danger-light-hover);
  border-color: var(--semi-color-danger-light-hover);
}
.semi-tree-select-error.semi-tree-select-focus {
  background-color: var(--semi-color-danger-light-default);
  border-color: var(--semi-color-danger);
}
.semi-tree-select-error:active {
  background-color: var(--semi-color-danger-light-active);
  border-color: var(--semi-color-danger-light-active);
}
.semi-tree-select-disabled {
  cursor: not-allowed;
  user-select: none;
  background-color: var(--semi-color-disabled-fill);
}
.semi-tree-select-disabled:hover {
  background-color: var(--semi-color-disabled-fill);
}
.semi-tree-select-disabled .semi-tree-select-selection,
.semi-tree-select-disabled .semi-tree-select-selection-placeholder {
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
}
.semi-tree-select-disabled .semi-tree-select-arrow,
.semi-tree-select-disabled .semi-tree-select-prefix,
.semi-tree-select-disabled .semi-tree-select-suffix {
  color: var(--semi-color-disabled-text);
}
.semi-tree-select-disabled .semi-tag {
  color: var(--semi-color-disabled-text);
  background-color: transparent;
}
.semi-tree-select-selection {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 100%;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  overflow: hidden;
  padding-left: 12px;
  padding-right: 0;
  cursor: pointer;
  color: var(--semi-color-text-0);
  position: relative;
}
.semi-tree-select-selection-content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.semi-tree-select-selection-placeholder {
  color: var(--semi-color-text-2);
}
.semi-tree-select-selection .semi-tag-group {
  height: inherit;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
}
.semi-tree-select-selection .semi-tag {
  margin: 1px 2px;
}
.semi-tree-select-selection-TriggerSearchItem {
  position: absolute;
  max-width: calc(100% - 12px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.semi-tree-select-selection-TriggerSearchItem-placeholder {
  opacity: 0.6;
}
.semi-tree-select-selection-TriggerSearchItem-disabled {
  cursor: not-allowed;
  color: var(--semi-color-disabled-text);
}
.semi-tree-select-selection .semi-tree-select-triggerSingleSearch-wrapper {
  width: 100%;
}
.semi-tree-select-selection .semi-tree-select-triggerSingleSearch-wrapper .semi-tree-select-inputTrigger .semi-input-wrapper {
  background: transparent;
}
.semi-tree-select-selection .semi-tree-select-triggerSingleSearch-wrapper .semi-input-wrapper {
  height: 100%;
  width: 100%;
  border: none;
  background-color: transparent;
}
.semi-tree-select-selection .semi-tree-select-triggerSingleSearch-wrapper .semi-input-wrapper-focus {
  border: none;
}
.semi-tree-select-selection .semi-tree-select-triggerSingleSearch-wrapper .semi-input {
  padding-left: 0;
  padding-right: 0;
}
.semi-tree-select-selection .semi-tagInput {
  border: hidden;
  background: transparent;
  min-height: 30px;
}
.semi-tree-select-selection .semi-tagInput-small {
  min-height: 22px;
}
.semi-tree-select-selection .semi-tagInput-large {
  min-height: 38px;
}
.semi-tree-select-selection .semi-tagInput .semi-tagInput-wrapper {
  padding-left: 4px;
  padding-right: 4px;
}
.semi-tree-select-selection .semi-tagInput .semi-tagInput-wrapper .semi-input-wrapper .semi-input {
  padding-left: 0;
}
.semi-tree-select-selection .semi-tagInput .semi-tagInput-wrapper .semi-input-wrapper-default {
  margin-top: 1px;
  margin-bottom: 1px;
}
.semi-tree-select-selection .semi-tagInput .semi-tagInput-wrapper .semi-input-wrapper-large {
  margin-top: 1px;
  margin-bottom: 1px;
}
.semi-tree-select-multiple-tagInput-notEmpty .semi-tagInput {
  margin-left: -4px;
}
.semi-tree-select-multiple-tagInput-empty .semi-tagInput {
  margin-left: 4px;
}
.semi-tree-select-multiple {
  display: inline-flex;
}
.semi-tree-select-multiple .semi-tree-select-selection {
  padding-left: 4px;
  padding-right: 0;
}
.semi-tree-select-multiple .semi-tree-select-selection-placeholder {
  padding-left: 8px;
}
.semi-tree-select-small {
  min-height: 24px;
  line-height: 24px;
}
.semi-tree-select-large {
  min-height: 40px;
  line-height: 40px;
}
.semi-tree-select-large .semi-tree-select-selection {
  font-size: 16px;
  line-height: 22px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.semi-tree-select-arrow {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  height: 100%;
  justify-content: center;
  width: 32px;
  color: var(--semi-color-text-2);
}
.semi-tree-select-inset-label {
  display: inline;
  margin-right: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--semi-color-text-2);
  flex-shrink: 0;
  white-space: nowrap;
}
.semi-tree-select.semi-tree-select-with-prefix {
  display: inline-flex;
  align-items: center;
}
.semi-tree-select-arrow, .semi-tree-select-clearbtn {
  display: inline-flex;
  align-items: center;
  height: 100%;
  flex-shrink: 0;
  justify-content: center;
  width: 32px;
  color: var(--semi-color-text-2);
}
.semi-tree-select-clearbtn:hover {
  color: var(--semi-color-primary-hover);
}
.semi-tree-select-clearbtn:active {
  color: var(--semi-color-primary-active);
}
.semi-tree-select-prefix, .semi-tree-select-suffix {
  display: inline;
  display: flex;
  justify-content: center;
  align-items: center;
}
.semi-tree-select-prefix-text, .semi-tree-select-suffix-text {
  margin: 0px 12px;
}
.semi-tree-select-prefix-icon, .semi-tree-select-suffix-icon {
  color: var(--semi-color-text-2);
  margin: 0px 8px;
}
.semi-tree-select-prefix.semi-tree-select-with-suffix .semi-tree-select-selection, .semi-tree-select-suffix.semi-tree-select-with-suffix .semi-tree-select-selection {
  padding-right: 0px;
}
.semi-tree-select-prefix-search-wrapper, .semi-tree-select-suffix-search-wrapper {
  padding: 8px 12px;
  border-bottom: 1px solid var(--semi-color-fill-0);
}
.semi-tree-select-prefix-maxTagCount, .semi-tree-select-suffix-maxTagCount {
  color: var(--semi-color-text-2);
  font-size: 14px;
}
.semi-tree-select-prefix-popover, .semi-tree-select-suffix-popover {
  max-height: 300px;
  overflow: auto;
}

.semi-tree-select-popover .semi-tree-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.semi-tree-select-popover .semi-tree-wrapper .semi-tree-option-list {
  flex: 1;
  min-width: 230px;
}
.semi-tree-select-popover .semi-tree-wrapper .semi-tree-search-wrapper {
  border-bottom: 1px var(--semi-color-fill-0) solid;
}

.semi-tree-select-borderless:not(:focus-within):not(:hover) {
  background-color: transparent;
  border-color: transparent;
}
.semi-tree-select-borderless:not(:focus-within):not(:hover) .semi-tree-select-arrow {
  opacity: 0;
}
.semi-tree-select-borderless:focus-within:not(:active) {
  background-color: transparent;
}
.semi-tree-select-borderless.semi-tree-select-error:not(:focus-within) {
  border-color: var(--semi-color-danger);
}
.semi-tree-select-borderless.semi-tree-select-warning:not(:focus-within) {
  border-color: var(--semi-color-warning);
}
.semi-tree-select-borderless.semi-tree-select-error:focus-within {
  border-color: var(--semi-color-danger);
}
.semi-tree-select-borderless.semi-tree-select-warning:focus-within {
  border-color: var(--semi-color-warning);
}

.semi-rtl .semi-tree-select,
.semi-portal-rtl .semi-tree-select {
  direction: rtl;
}
.semi-rtl .semi-tree-select .semi-tagInput .semi-input,
.semi-portal-rtl .semi-tree-select .semi-tagInput .semi-input {
  padding-right: 0;
}
.semi-rtl .semi-tree-select-multiple-tagInput-notEmpty .semi-tagInput,
.semi-portal-rtl .semi-tree-select-multiple-tagInput-notEmpty .semi-tagInput {
  margin-left: 0;
  margin-right: -4px;
}
.semi-rtl .semi-tree-select-multiple-tagInput-empty .semi-tagInput,
.semi-portal-rtl .semi-tree-select-multiple-tagInput-empty .semi-tagInput {
  margin-left: 0;
  margin-right: 4px;
}
.semi-rtl .semi-tree-select-selection,
.semi-portal-rtl .semi-tree-select-selection {
  padding-right: 12px;
  padding-left: 0;
}
.semi-rtl .semi-tree-select-multiple .semi-tree-select-selection,
.semi-portal-rtl .semi-tree-select-multiple .semi-tree-select-selection {
  padding-left: 0;
  padding-right: 4px;
}
.semi-rtl .semi-tree-select-multiple .semi-tree-select-selection-placeholder,
.semi-portal-rtl .semi-tree-select-multiple .semi-tree-select-selection-placeholder {
  padding-left: 0;
  padding-right: 8px;
}
.semi-rtl .semi-tree-select-inset-label,
.semi-portal-rtl .semi-tree-select-inset-label {
  margin-right: 0;
  margin-left: 12px;
}
.semi-rtl .semi-tree-select-prefix.semi-tree-select-with-suffix .semi-tree-select-selection, .semi-rtl .semi-tree-select-suffix.semi-tree-select-with-suffix .semi-tree-select-selection,
.semi-portal-rtl .semi-tree-select-prefix.semi-tree-select-with-suffix .semi-tree-select-selection,
.semi-portal-rtl .semi-tree-select-suffix.semi-tree-select-with-suffix .semi-tree-select-selection {
  padding-right: auto;
  padding-left: 0;
}
.semi-rtl .semi-tree-select-popover,
.semi-portal-rtl .semi-tree-select-popover {
  direction: rtl;
}