/* shadow */
/* sizing */
/* spacing */
.semi-banner {
  padding: 12px 12px;
}
.semi-banner-content-wrapper {
  display: flex;
  flex-direction: row;
}
.semi-banner-description {
  margin: 0;
}
.semi-banner .semi-banner-content-wrapper .semi-banner-content {
  display: flex;
  flex: 1;
  align-items: top;
}
.semi-banner-in-container {
  border-radius: var(--semi-border-radius-small);
}
.semi-banner-in-container .semi-banner-content-wrapper .semi-banner-content-body {
  flex: 1;
}
.semi-banner-in-container .semi-banner-content-wrapper .semi-banner-content-body .semi-banner-title + .semi-banner-description {
  margin-top: 2px;
}
.semi-banner-full .semi-banner-content-wrapper .semi-banner-content {
  justify-content: center;
}
.semi-banner-full .semi-banner-content-wrapper .semi-banner-icon,
.semi-banner-full .semi-banner-content-wrapper .semi-banner-content-body {
  display: flex;
  align-items: center;
}
.semi-banner-close {
  margin-left: 12px;
  height: 24px;
  width: 24px;
}
.semi-banner-extra {
  margin-top: 8px;
}
.semi-banner-icon {
  display: flex;
  margin-right: 12px;
}
.semi-banner-info {
  background-color: var(--semi-color-info-light-default);
  color: var(--semi-color-info);
}
.semi-banner-info.semi-banner-bordered {
  border: 1px solid var(--semi-color-info);
}
.semi-banner-warning {
  background-color: var(--semi-color-warning-light-default);
  color: var(--semi-color-warning);
}
.semi-banner-warning.semi-banner-bordered {
  border: 1px solid var(--semi-color-warning);
}
.semi-banner-success {
  background-color: var(--semi-color-success-light-default);
  color: var(--semi-color-success);
}
.semi-banner-success.semi-banner-bordered {
  border: 1px solid var(--semi-color-success);
}
.semi-banner-danger {
  background-color: var(--semi-color-danger-light-default);
  color: var(--semi-color-danger);
}
.semi-banner-danger.semi-banner-bordered {
  border: 1px solid var(--semi-color-danger);
}

.semi-rtl .semi-banner,
.semi-portal-rtl .semi-banner {
  direction: rtl;
}
.semi-rtl .semi-banner-close,
.semi-portal-rtl .semi-banner-close {
  margin-left: 0;
  margin-right: 12px;
  height: 24px;
  width: 24px;
}
.semi-rtl .semi-banner-icon,
.semi-portal-rtl .semi-banner-icon {
  margin-right: 0;
  margin-left: 12px;
}