/* shadow */
/* sizing */
/* spacing */
.semi-descriptions {
  line-height: 20px;
}
.semi-descriptions table,
.semi-descriptions tr,
.semi-descriptions th,
.semi-descriptions td {
  margin: 0;
  padding: 0;
  border: 0;
}
.semi-descriptions th {
  padding-right: 24px;
}
.semi-descriptions .semi-descriptions-item {
  margin: 0;
  padding-bottom: 12px;
  text-align: left;
  vertical-align: top;
}
.semi-descriptions-key {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  min-height: 14px;
  white-space: nowrap;
  color: var(--semi-color-text-2);
}
.semi-descriptions-value {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--semi-color-text-0);
}
.semi-descriptions-center .semi-descriptions-item-th {
  text-align: right;
}
.semi-descriptions-center .semi-descriptions-item-td {
  text-align: left;
}
.semi-descriptions-left .semi-descriptions-item-th,
.semi-descriptions-left .semi-descriptions-item-td {
  text-align: left;
}
.semi-descriptions-justify .semi-descriptions-item-th {
  text-align: left;
}
.semi-descriptions-justify .semi-descriptions-item-td {
  text-align: right;
}
.semi-descriptions-plain .semi-descriptions-key,
.semi-descriptions-plain .semi-descriptions-value {
  display: inline-block;
}
.semi-descriptions-plain .semi-descriptions-value {
  padding-left: 8px;
}
.semi-descriptions-plain .semi-descriptions-value .semi-tag {
  vertical-align: middle;
}
.semi-descriptions-double tbody {
  display: flex;
  flex-wrap: wrap;
}
.semi-descriptions-double tr {
  display: inline-flex;
  flex-direction: column;
}
.semi-descriptions-double .semi-descriptions-item {
  padding: 0;
  flex: 1;
}
.semi-descriptions-double .semi-descriptions-value {
  font-weight: 600;
}
.semi-descriptions-double-small .semi-descriptions-item {
  padding-right: 48px;
}
.semi-descriptions-double-small .semi-descriptions-key {
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding-bottom: 0;
  font-size: 12px;
}
.semi-descriptions-double-small .semi-descriptions-value {
  font-size: 16px;
  line-height: 22px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
}
.semi-descriptions-double-medium .semi-descriptions-item {
  padding-right: 60px;
}
.semi-descriptions-double-medium .semi-descriptions-key {
  padding-bottom: 4px;
  font-size: 14px;
}
.semi-descriptions-double-medium .semi-descriptions-value {
  font-size: 20px;
  line-height: 28px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px;
}
.semi-descriptions-double-large .semi-descriptions-item {
  padding-right: 80px;
}
.semi-descriptions-double-large .semi-descriptions-key {
  padding-bottom: 4px;
  font-size: 14px;
}
.semi-descriptions-double-large .semi-descriptions-value {
  font-size: 28px;
  line-height: 40px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 28px;
}

.semi-rtl .semi-descriptions,
.semi-portal-rtl .semi-descriptions {
  direction: rtl;
}
.semi-rtl .semi-descriptions th,
.semi-portal-rtl .semi-descriptions th {
  direction: rtl;
  padding-right: 0;
  padding-left: 24px;
}
.semi-rtl .semi-descriptions .semi-descriptions-item,
.semi-portal-rtl .semi-descriptions .semi-descriptions-item {
  text-align: right;
}
.semi-rtl .semi-descriptions-center .semi-descriptions-item-th,
.semi-portal-rtl .semi-descriptions-center .semi-descriptions-item-th {
  text-align: left;
}
.semi-rtl .semi-descriptions-center .semi-descriptions-item-td,
.semi-portal-rtl .semi-descriptions-center .semi-descriptions-item-td {
  text-align: right;
}
.semi-rtl .semi-descriptions-left .semi-descriptions-item-th,
.semi-rtl .semi-descriptions-left .semi-descriptions-item-td,
.semi-portal-rtl .semi-descriptions-left .semi-descriptions-item-th,
.semi-portal-rtl .semi-descriptions-left .semi-descriptions-item-td {
  text-align: left;
}
.semi-rtl .semi-descriptions-justify .semi-descriptions-item-th,
.semi-portal-rtl .semi-descriptions-justify .semi-descriptions-item-th {
  text-align: right;
}
.semi-rtl .semi-descriptions-justify .semi-descriptions-item-td,
.semi-portal-rtl .semi-descriptions-justify .semi-descriptions-item-td {
  text-align: left;
}
.semi-rtl .semi-descriptions-plain .semi-descriptions-key,
.semi-rtl .semi-descriptions-plain .semi-descriptions-value,
.semi-portal-rtl .semi-descriptions-plain .semi-descriptions-key,
.semi-portal-rtl .semi-descriptions-plain .semi-descriptions-value {
  display: inline-block;
}
.semi-rtl .semi-descriptions-plain .semi-descriptions-value,
.semi-portal-rtl .semi-descriptions-plain .semi-descriptions-value {
  padding-left: 0;
  padding-right: 8px;
}
.semi-rtl .semi-descriptions-plain .semi-descriptions-value .semi-tag,
.semi-portal-rtl .semi-descriptions-plain .semi-descriptions-value .semi-tag {
  vertical-align: middle;
}
.semi-rtl .semi-descriptions-double,
.semi-portal-rtl .semi-descriptions-double {
  direction: rtl;
}
.semi-rtl .semi-descriptions-double .semi-descriptions-item,
.semi-portal-rtl .semi-descriptions-double .semi-descriptions-item {
  text-align: right;
}
.semi-rtl .semi-descriptions-double-small .semi-descriptions-item,
.semi-portal-rtl .semi-descriptions-double-small .semi-descriptions-item {
  padding-right: 0;
  padding-left: 48px;
}
.semi-rtl .semi-descriptions-double-medium .semi-descriptions-item,
.semi-portal-rtl .semi-descriptions-double-medium .semi-descriptions-item {
  padding-right: 0;
  padding-left: 60px;
}
.semi-rtl .semi-descriptions-double-large .semi-descriptions-item,
.semi-portal-rtl .semi-descriptions-double-large .semi-descriptions-item {
  padding-right: 0;
  padding-left: 80px;
}