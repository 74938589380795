/* shadow */
/* sizing */
/* spacing */
.semi-badge {
  position: relative;
  display: inline-block;
}
.semi-badge-dot {
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  border-radius: var(--semi-border-radius-circle);
  background-color: var(--semi-color-bg-1);
  border: 1px var(--semi-color-bg-1) solid;
  z-index: 1;
}
.semi-badge-count {
  box-sizing: border-box;
  height: 18px;
  min-width: 18px;
  border-radius: 9px;
  padding: 0px 4px;
  background-color: var(--semi-color-bg-1);
  border: 1px var(--semi-color-bg-1) solid;
  z-index: 1;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 14px;
  color: var(--semi-color-bg-2);
}
.semi-badge-rightTop {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}
.semi-badge-rightBottom {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(50%, 50%);
  transform-origin: 100% 0%;
}
.semi-badge-leftTop {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  transform-origin: 100% 0%;
}
.semi-badge-leftBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-50%, 50%);
  transform-origin: 100% 0%;
}
.semi-badge-custom {
  display: flex;
}
.semi-badge-block {
  position: static;
  display: inline-block;
}
.semi-badge-light::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--semi-color-bg-2);
  z-index: -1;
}
.semi-badge-light::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background-color: inherit;
  z-index: -1;
}
.semi-badge-primary.semi-badge-solid {
  background-color: var(--semi-color-primary);
}
.semi-badge-primary.semi-badge-light {
  background-color: var(--semi-color-primary-light-default);
  color: var(--semi-color-primary);
}
.semi-badge-primary.semi-badge-inverted {
  color: var(--semi-color-primary);
}
.semi-badge-secondary.semi-badge-solid {
  background-color: var(--semi-color-secondary);
}
.semi-badge-secondary.semi-badge-light {
  background-color: var(--semi-color-secondary-light-default);
  color: var(--semi-color-secondary);
}
.semi-badge-secondary.semi-badge-inverted {
  color: var(--semi-color-secondary);
}
.semi-badge-tertiary.semi-badge-solid {
  background-color: var(--semi-color-tertiary);
}
.semi-badge-tertiary.semi-badge-light {
  background-color: var(--semi-color-tertiary-light-default);
  color: var(--semi-color-tertiary);
}
.semi-badge-tertiary.semi-badge-inverted {
  color: var(--semi-color-tertiary);
}
.semi-badge-danger.semi-badge-solid {
  background-color: var(--semi-color-danger);
}
.semi-badge-danger.semi-badge-light {
  background-color: var(--semi-color-danger-light-default);
  color: var(--semi-color-danger);
}
.semi-badge-danger.semi-badge-inverted {
  color: var(--semi-color-danger);
}
.semi-badge-warning.semi-badge-solid {
  background-color: var(--semi-color-warning);
}
.semi-badge-warning.semi-badge-light {
  background-color: var(--semi-color-warning-light-default);
  color: var(--semi-color-warning);
}
.semi-badge-warning.semi-badge-inverted {
  color: var(--semi-color-warning);
}
.semi-badge-success.semi-badge-solid {
  background-color: var(--semi-color-success);
}
.semi-badge-success.semi-badge-light {
  background-color: var(--semi-color-success-light-default);
  color: var(--semi-color-success);
}
.semi-badge-success.semi-badge-inverted {
  color: var(--semi-color-success);
}

.semi-rtl .semi-badge,
.semi-portal-rtl .semi-badge {
  direction: rtl;
}