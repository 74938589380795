/* shadow */
/* sizing */
/* spacing */
.semi-notification-wrapper {
  position: fixed;
}

.semi-notification-list {
  position: fixed;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  z-index: 1010;
  pointer-events: none;
}
.semi-notification-list[placement=topRight] {
  right: 0;
  top: 0;
}
.semi-notification-list[placement=top] {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.semi-notification-list[placement=topLeft] {
  left: 0;
  top: 0;
}
.semi-notification-list[placement=bottom] {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.semi-notification-list[placement=bottomRight] {
  bottom: 0;
  right: 0;
}
.semi-notification-list[placement=bottomLeft] {
  bottom: 0;
  left: 0;
}

.semi-notification-notice {
  box-shadow: var(--semi-shadow-elevated);
  border-radius: var(--semi-border-radius-medium);
  padding-top: 16px;
  padding-right: 12px;
  padding-bottom: 16px;
  padding-left: 20px;
  margin: 20px;
  width: auto;
  min-width: 320px;
  background-color: var(--semi-color-bg-3);
  position: relative;
  display: flex;
  pointer-events: auto;
}
.semi-notification-notice-icon {
  width: 24px;
  font-size: 16px;
  line-height: 22px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  margin-right: 12px;
}
.semi-notification-notice-info {
  color: var(--semi-color-info);
}
.semi-notification-notice-warning {
  color: var(--semi-color-warning);
}
.semi-notification-notice-error {
  color: var(--semi-color-danger);
}
.semi-notification-notice-success {
  color: var(--semi-color-success);
}
.semi-notification-notice-light.semi-notification-notice-warning {
  background-image: linear-gradient(0deg, var(--semi-color-warning-light-default), var(--semi-color-warning-light-default));
  background-color: var(--semi-color-bg-0);
  border: 1px solid var(--semi-color-warning);
}
.semi-notification-notice-light.semi-notification-notice-success {
  background-image: linear-gradient(0deg, var(--semi-color-success-light-default), var(--semi-color-success-light-default));
  background-color: var(--semi-color-bg-0);
  border: 1px solid var(--semi-color-success);
}
.semi-notification-notice-light.semi-notification-notice-info, .semi-notification-notice-light.semi-notification-notice-default {
  background-image: linear-gradient(0deg, var(--semi-color-info-light-default), var(--semi-color-info-light-default));
  background-color: var(--semi-color-bg-0);
  border: 1px solid var(--semi-color-info);
}
.semi-notification-notice-light.semi-notification-notice-error {
  background-image: linear-gradient(0deg, var(--semi-color-danger-light-default), var(--semi-color-danger-light-default));
  background-color: var(--semi-color-bg-0);
  border: 1px solid var(--semi-color-danger);
}
.semi-notification-notice-title {
  font-size: 16px;
  line-height: 22px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  color: var(--semi-color-text-0);
  margin-bottom: 4px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.semi-notification-notice-inner {
  display: flex;
  width: 100%;
  min-width: 0;
}
.semi-notification-notice-content-wrapper {
  flex: 1 1 auto;
  margin-right: 8px;
  min-width: 0;
}
.semi-notification-notice-content {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: var(--semi-color-text-1);
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.semi-notification-notice-icon-close {
  height: 24px;
}
.semi-notification-notice-icon-close .semi-icon-close {
  color: var(--semi-color-text-2);
}
.semi-notification-notice-controls {
  display: flex;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  cursor: pointer;
}
.semi-notification-notice-detail {
  text-align: center;
  flex-grow: 1;
}
.semi-notification-notice-later {
  text-align: center;
  flex-grow: 1;
}
@keyframes semi-notification-slideShow_top {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes semi-notification-slideHide_top {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}
@keyframes semi-notification-slideShow_topLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes semi-notification-slideHide_topLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}
@keyframes semi-notification-slideShow_topRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes semi-notification-slideHide_topRight {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}
@keyframes semi-notification-slideShow_bottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes semi-notification-slideHide_bottom {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
}
@keyframes semi-notification-slideShow_bottomLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes semi-notification-slideHide_bottomLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}
@keyframes semi-notification-slideShow_bottomRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes semi-notification-slideHide_bottomRight {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}
.semi-notification-notice-animation-show_top {
  animation: semi-notification-slideShow_top 300ms cubic-bezier(0.62, 0.63, 0, 1.13) 0ms;
  animation-fill-mode: forwards;
}
.semi-notification-notice-animation-hide_top {
  animation: semi-notification-slideHide_top 300ms cubic-bezier(0.62, 0.63, 0, 1.13) 0ms;
  animation-fill-mode: forwards;
}
.semi-notification-notice-animation-show_topLeft {
  animation: semi-notification-slideShow_topLeft 300ms cubic-bezier(0.62, 0.63, 0, 1.13) 0ms;
  animation-fill-mode: forwards;
}
.semi-notification-notice-animation-hide_topLeft {
  animation: semi-notification-slideHide_topLeft 300ms cubic-bezier(0.62, 0.63, 0, 1.13) 0ms;
  animation-fill-mode: forwards;
}
.semi-notification-notice-animation-show_topRight {
  animation: semi-notification-slideShow_topRight 300ms cubic-bezier(0.62, 0.63, 0, 1.13) 0ms;
  animation-fill-mode: forwards;
}
.semi-notification-notice-animation-hide_topRight {
  animation: semi-notification-slideHide_topRight 300ms cubic-bezier(0.62, 0.63, 0, 1.13) 0ms;
  animation-fill-mode: forwards;
}
.semi-notification-notice-animation-show_bottom {
  animation: semi-notification-slideShow_bottom 300ms cubic-bezier(0.62, 0.63, 0, 1.13) 0ms;
  animation-fill-mode: forwards;
}
.semi-notification-notice-animation-hide_bottom {
  animation: semi-notification-slideHide_bottom 300ms cubic-bezier(0.62, 0.63, 0, 1.13) 0ms;
  animation-fill-mode: forwards;
}
.semi-notification-notice-animation-show_bottomLeft {
  animation: semi-notification-slideShow_bottomLeft 300ms cubic-bezier(0.62, 0.63, 0, 1.13) 0ms;
  animation-fill-mode: forwards;
}
.semi-notification-notice-animation-hide_bottomLeft {
  animation: semi-notification-slideHide_bottomLeft 300ms cubic-bezier(0.62, 0.63, 0, 1.13) 0ms;
  animation-fill-mode: forwards;
}
.semi-notification-notice-animation-show_bottomRight {
  animation: semi-notification-slideShow_bottomRight 300ms cubic-bezier(0.62, 0.63, 0, 1.13) 0ms;
  animation-fill-mode: forwards;
}
.semi-notification-notice-animation-hide_bottomRight {
  animation: semi-notification-slideHide_bottomRight 300ms cubic-bezier(0.62, 0.63, 0, 1.13) 0ms;
  animation-fill-mode: forwards;
}

.semi-notification-notice.semi-notification-notice-rtl {
  padding-right: 20px;
  padding-left: 12px;
}
.semi-notification-notice-rtl {
  direction: rtl;
}
.semi-notification-notice-rtl .semi-notification-notice-icon {
  margin-right: 12px;
}
.semi-notification-notice-rtl .semi-notification-notice-content-wrapper {
  margin-right: 8px;
}