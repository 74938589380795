/* shadow */
/* sizing */
/* spacing */
.semi-empty {
  display: flex;
}
.semi-empty-image {
  display: flex;
  justify-content: center;
  user-select: none;
  -webkit-user-drag: none;
  pointer-events: none;
}
.semi-empty-vertical {
  align-items: center;
  flex-direction: column;
}
.semi-empty-vertical .semi-empty-content {
  margin-top: 24px;
}
.semi-empty-vertical .semi-empty-title,
.semi-empty-vertical .semi-empty-description {
  text-align: center;
}
.semi-empty-horizontal .semi-empty-content {
  margin-left: 32px;
}
.semi-empty-title.semi-typography {
  display: block;
  font-weight: 600;
}
.semi-empty-title + .semi-empty-description {
  margin-top: 16px;
}
.semi-empty-description {
  color: var(--semi-color-text-1);
}
.semi-empty-footer {
  margin-top: 24px;
}

.semi-rtl .semi-empty,
.semi-portal-rtl .semi-empty {
  direction: rtl;
}
.semi-rtl .semi-empty-horizontal .semi-empty-content,
.semi-portal-rtl .semi-empty-horizontal .semi-empty-content {
  margin-left: auto;
  margin-right: 32px;
}