/* shadow */
/* sizing */
/* spacing */
.semi-carousel {
  position: relative;
  overflow: hidden;
}
.semi-carousel-content {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.semi-carousel-content-item {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.semi-carousel-content-item-current {
  z-index: 1;
}
.semi-carousel-content-fade > * {
  opacity: 0;
}
.semi-carousel-content-fade .semi-carousel-content-item-current {
  opacity: 1;
}
.semi-carousel-content-slide > *:not(.semi-carousel-content-item-current) {
  visibility: hidden;
}
.semi-carousel-content-slide .semi-carousel-content-item-slide-out {
  display: block;
  animation: semi-carousel-content-item-keyframe-slide-out;
  animation-fill-mode: forwards;
}
.semi-carousel-content-slide .semi-carousel-content-item-slide-in {
  display: block;
  animation: semi-carousel-content-item-keyframe-slide-in;
  animation-fill-mode: forwards;
}
.semi-carousel-content-reverse .semi-carousel-content-item-slide-out {
  animation: semi-carousel-content-item-keyframe-slide-out-reverse;
  animation-fill-mode: forwards;
}
.semi-carousel-content-reverse .semi-carousel-content-item-slide-in {
  animation: semi-carousel-content-item-keyframe-slide-in-reverse;
  animation-fill-mode: forwards;
}
.semi-carousel-indicator {
  display: flex;
  align-items: flex-end;
  z-index: 2;
}
.semi-carousel-indicator-left {
  position: absolute;
  left: 32px;
  bottom: 32px;
}
.semi-carousel-indicator-center {
  position: absolute;
  left: 50%;
  bottom: 32px;
  transform: translate(-50%);
}
.semi-carousel-indicator-right {
  position: absolute;
  right: 32px;
  bottom: 32px;
}
.semi-carousel-indicator-dot .semi-carousel-indicator-item {
  border-radius: 50%;
  cursor: pointer;
}
.semi-carousel-indicator-dot .semi-carousel-indicator-item:not(:last-child) {
  margin-right: 8px;
}
.semi-carousel-indicator-dot .semi-carousel-indicator-item-small {
  width: 8px;
  height: 8px;
}
.semi-carousel-indicator-dot .semi-carousel-indicator-item-medium {
  width: 12px;
  height: 12px;
}
.semi-carousel-indicator-dot .semi-carousel-indicator-item-primary {
  background-color: rgba(var(--semi-blue-6), 0.4);
  transition: background-color var(--semi-transition_delay-none) var(--semi-transition_function-easeOut) var(--semi-transition_delay-none);
}
.semi-carousel-indicator-dot .semi-carousel-indicator-item-primary.semi-carousel-indicator-item-active {
  background: rgba(var(--semi-blue-6), 1);
}
.semi-carousel-indicator-dot .semi-carousel-indicator-item-primary:hover {
  background-color: rgba(var(--semi-blue-6), 0.7);
}
.semi-carousel-indicator-dot .semi-carousel-indicator-item-primary:active {
  background: rgba(var(--semi-blue-6), 1);
}
.semi-carousel-indicator-dot .semi-carousel-indicator-item-light {
  background-color: rgba(var(--semi-white), 0.4);
  transition: background-color var(--semi-transition_delay-none) var(--semi-transition_function-easeOut) var(--semi-transition_delay-none);
}
.semi-carousel-indicator-dot .semi-carousel-indicator-item-light.semi-carousel-indicator-item-active {
  background: rgba(var(--semi-white), 1);
}
.semi-carousel-indicator-dot .semi-carousel-indicator-item-light:hover {
  background-color: rgba(var(--semi-white), 0.7);
}
.semi-carousel-indicator-dot .semi-carousel-indicator-item-light:active {
  background: rgba(var(--semi-white), 1);
}
.semi-carousel-indicator-dot .semi-carousel-indicator-item-dark {
  background-color: rgba(var(--semi-black), 0.5);
  transition: background-color var(--semi-transition_delay-none) var(--semi-transition_function-easeOut) var(--semi-transition_delay-none);
}
.semi-carousel-indicator-dot .semi-carousel-indicator-item-dark.semi-carousel-indicator-item-active {
  background-color: rgba(var(--semi-black), 1);
}
.semi-carousel-indicator-dot .semi-carousel-indicator-item-dark:hover {
  background-color: rgba(var(--semi-black), 0.7);
}
.semi-carousel-indicator-dot .semi-carousel-indicator-item-dark:active {
  background: rgba(var(--semi-black), 1);
}
.semi-carousel-indicator-line {
  width: 240px;
}
.semi-carousel-indicator-line .semi-carousel-indicator-item {
  flex: 1;
  cursor: pointer;
}
.semi-carousel-indicator-line .semi-carousel-indicator-item:not(:last-child) {
  margin-right: 4px;
}
.semi-carousel-indicator-line .semi-carousel-indicator-item-small {
  height: 4px;
}
.semi-carousel-indicator-line .semi-carousel-indicator-item-medium {
  height: 6px;
}
.semi-carousel-indicator-line .semi-carousel-indicator-item-primary {
  background-color: rgba(var(--semi-blue-6), 0.4);
}
.semi-carousel-indicator-line .semi-carousel-indicator-item-primary.semi-carousel-indicator-item-active {
  background: rgba(var(--semi-blue-6), 1);
}
.semi-carousel-indicator-line .semi-carousel-indicator-item-primary:hover {
  background-color: rgba(var(--semi-blue-6), 0.7);
}
.semi-carousel-indicator-line .semi-carousel-indicator-item-primary:active {
  background: rgba(var(--semi-blue-6), 1);
}
.semi-carousel-indicator-line .semi-carousel-indicator-item-light {
  background-color: rgba(var(--semi-white), 0.4);
}
.semi-carousel-indicator-line .semi-carousel-indicator-item-light.semi-carousel-indicator-item-active {
  background: rgba(var(--semi-white), 1);
}
.semi-carousel-indicator-line .semi-carousel-indicator-item-light:hover {
  background-color: rgba(var(--semi-white), 0.7);
}
.semi-carousel-indicator-line .semi-carousel-indicator-item-light:active {
  background: rgba(var(--semi-white), 1);
}
.semi-carousel-indicator-line .semi-carousel-indicator-item-dark {
  background-color: rgba(var(--semi-black), 0.5);
}
.semi-carousel-indicator-line .semi-carousel-indicator-item-dark.semi-carousel-indicator-item-active {
  background: rgba(var(--semi-black), 1);
}
.semi-carousel-indicator-line .semi-carousel-indicator-item-dark:hover {
  background-color: rgba(var(--semi-black), 0.7);
}
.semi-carousel-indicator-line .semi-carousel-indicator-item-dark:active {
  background: rgba(var(--semi-black), 1);
}
.semi-carousel-indicator-columnar .semi-carousel-indicator-item {
  cursor: pointer;
}
.semi-carousel-indicator-columnar .semi-carousel-indicator-item:not(:last-child) {
  margin-right: 4px;
}
.semi-carousel-indicator-columnar .semi-carousel-indicator-item-small {
  width: 4px;
  height: 12px;
}
.semi-carousel-indicator-columnar .semi-carousel-indicator-item-small.semi-carousel-indicator-item-active {
  height: 20px;
}
.semi-carousel-indicator-columnar .semi-carousel-indicator-item-medium {
  width: 6px;
  height: 20px;
}
.semi-carousel-indicator-columnar .semi-carousel-indicator-item-medium.semi-carousel-indicator-item-active {
  height: 28px;
}
.semi-carousel-indicator-columnar .semi-carousel-indicator-item-primary {
  background-color: rgba(var(--semi-blue-6), 0.4);
}
.semi-carousel-indicator-columnar .semi-carousel-indicator-item-primary.semi-carousel-indicator-item-active {
  background: rgba(var(--semi-blue-6), 1);
}
.semi-carousel-indicator-columnar .semi-carousel-indicator-item-primary:hover {
  background-color: rgba(var(--semi-blue-6), 0.7);
}
.semi-carousel-indicator-columnar .semi-carousel-indicator-item-primary:active {
  background: rgba(var(--semi-blue-6), 1);
}
.semi-carousel-indicator-columnar .semi-carousel-indicator-item-light {
  background-color: rgba(var(--semi-white), 0.4);
}
.semi-carousel-indicator-columnar .semi-carousel-indicator-item-light.semi-carousel-indicator-item-active {
  background: rgba(var(--semi-white), 1);
}
.semi-carousel-indicator-columnar .semi-carousel-indicator-item-light:hover {
  background-color: rgba(var(--semi-white), 0.7);
}
.semi-carousel-indicator-columnar .semi-carousel-indicator-item-light:active {
  background: rgba(var(--semi-white), 1);
}
.semi-carousel-indicator-columnar .semi-carousel-indicator-item-dark {
  background-color: rgba(var(--semi-black), 0.5);
}
.semi-carousel-indicator-columnar .semi-carousel-indicator-item-dark.semi-carousel-indicator-item-active {
  background: rgba(var(--semi-black), 1);
}
.semi-carousel-indicator-columnar .semi-carousel-indicator-item-dark:hover {
  background-color: rgba(var(--semi-black), 0.7);
}
.semi-carousel-indicator-columnar .semi-carousel-indicator-item-dark:active {
  background: rgba(var(--semi-black), 1);
}
.semi-carousel-arrow {
  display: flex;
  font-size: 32px;
  cursor: pointer;
}
.semi-carousel-arrow-prev {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  z-index: 2;
}
.semi-carousel-arrow-next {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  z-index: 2;
}
.semi-carousel-arrow-light {
  color: rgba(var(--semi-white), 0.4);
  transition: color var(--semi-transition_delay-none) var(--semi-transition_function-easeOut) var(--semi-transition_delay-none);
}
.semi-carousel-arrow-light:hover {
  color: rgba(var(--semi-white), 1);
}
.semi-carousel-arrow-primary {
  color: rgba(var(--semi-blue-6), 0.4);
  transition: color var(--semi-transition_delay-none) var(--semi-transition_function-easeOut) var(--semi-transition_delay-none);
}
.semi-carousel-arrow-primary:hover {
  color: rgba(var(--semi-blue-6), 1);
}
.semi-carousel-arrow-dark {
  color: rgba(var(--semi-black), 0.5);
  transition: color var(--semi-transition_delay-none) var(--semi-transition_function-easeOut) var(--semi-transition_delay-none);
}
.semi-carousel-arrow-dark:hover {
  color: rgba(var(--semi-black), 1);
}
.semi-carousel-arrow-hover div {
  z-index: 2;
  opacity: 0;
}
.semi-carousel:hover .semi-carousel-arrow-hover div {
  opacity: 1;
}

@keyframes semi-carousel-content-item-keyframe-slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes semi-carousel-content-item-keyframe-slide-out {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes semi-carousel-content-item-keyframe-slide-in-reverse {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes semi-carousel-content-item-keyframe-slide-out-reverse {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
.semi-rtl .semi-carousel,
.semi-portal-rtl .semi-carousel {
  direction: rtl;
}
.semi-rtl .semi-carousel-indicator,
.semi-portal-rtl .semi-carousel-indicator {
  display: flex;
}
.semi-rtl .semi-carousel-indicator-dot .semi-carousel-indicator-item:not(:last-child),
.semi-portal-rtl .semi-carousel-indicator-dot .semi-carousel-indicator-item:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.semi-rtl .semi-carousel-indicator-columnar .semi-carousel-indicator-item:not(:last-child),
.semi-portal-rtl .semi-carousel-indicator-columnar .semi-carousel-indicator-item:not(:last-child) {
  margin-right: 0;
  margin-left: 4px;
}
.semi-rtl .semi-carousel-arrow,
.semi-portal-rtl .semi-carousel-arrow {
  flex-direction: row-reverse;
}
.semi-rtl .semi-carousel-arrow-prev,
.semi-portal-rtl .semi-carousel-arrow-prev {
  left: auto;
  right: 20px;
  transform: scaleX(-1) translateY(-50%);
  z-index: 2;
}
.semi-rtl .semi-carousel-arrow-next,
.semi-portal-rtl .semi-carousel-arrow-next {
  left: 20px;
  transform: scaleX(-1) translateY(-50%);
  right: auto;
  z-index: 2;
}