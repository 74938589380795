/* shadow */
/* sizing */
/* spacing */
.semi-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: auto;
}
.semi-layout, .semi-layout-header, .semi-layout-footer, .semi-layout-content, .semi-layout-sider, .semi-layout-sider-children {
  box-sizing: border-box;
}
.semi-layout-header, .semi-layout-footer {
  flex: 0 0 auto;
}
.semi-layout-content {
  flex: auto;
  min-height: auto;
}
.semi-layout-sider {
  position: relative;
  min-width: auto;
}
.semi-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}

.semi-layout-has-sider {
  flex-direction: row;
}
.semi-layout-has-sider > .semi-layout, .semi-layout-has-sider > .semi-layout-content {
  overflow-x: hidden;
}

.semi-rtl .semi-layout,
.semi-portal-rtl .semi-layout {
  direction: rtl;
}