/* shadow */
/* sizing */
/* spacing */
.semi-radio {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: inline-flex;
  column-gap: 8px;
  transition: background-color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none), border var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
  min-height: 20px;
  min-width: 16px;
  cursor: pointer;
  vertical-align: bottom;
  text-align: left;
}
.semi-radio.semi-radio-vertical {
  display: block;
}
.semi-radio input[type=checkbox],
.semi-radio input[type=radio] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
}
.semi-radio:hover .semi-radio-inner-display {
  background: var(--semi-color-fill-0);
  border: solid 1px var(--semi-color-focus-border);
}
.semi-radio:hover.semi-radio-cardRadioGroup .semi-radio-inner-display {
  background: var(--semi-color-white);
}
.semi-radio:hover .semi-radio-inner-checked .semi-radio-inner-display {
  background: var(--semi-color-primary-hover);
  border-color: var(--semi-color-primary-hover);
}
.semi-radio:active .semi-radio-inner-display {
  background: var(--semi-color-fill-1);
}
.semi-radio:active.semi-radio-cardRadioGroup .semi-radio-inner-display {
  background: var(--semi-color-white);
}
.semi-radio:active .semi-radio-inner-checked .semi-radio-inner-display {
  background: var(--semi-color-primary-active);
  border-color: var(--semi-color-primary-active);
}
.semi-radio-buttonRadioComponent {
  padding: 4px;
  background: var(--semi-color-fill-0);
  border-radius: var(--semi-border-radius-small);
}
.semi-radio-buttonRadioGroup {
  position: relative;
  padding: 4px;
  border-radius: var(--semi-border-radius-small);
  line-height: 16px;
}
.semi-radio-buttonRadioGroup:not(:last-child) {
  padding-right: 0;
}
.semi-radio-buttonRadioGroup-small {
  padding: 2px 4px;
  line-height: 16px;
}
.semi-radio-buttonRadioGroup-large {
  padding: 4px;
  line-height: 20px;
}
.semi-radio-cardRadioGroup {
  flex-wrap: nowrap;
  border-radius: var(--semi-border-radius-small);
  padding: 12px 16px;
  background: transparent;
  border: 1px solid transparent;
  transition: background-color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none), border var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
}
.semi-radio-cardRadioGroup .semi-radio-inner {
  flex-shrink: 0;
}
.semi-radio-cardRadioGroup .semi-radio-inner-display {
  background: var(--semi-color-white);
}
.semi-radio-cardRadioGroup .semi-radio-addon {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--semi-color-text-0);
}
.semi-radio-cardRadioGroup .semi-radio-extra {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--semi-color-text-2);
  padding-left: 0;
}
.semi-radio-cardRadioGroup:active {
  background: var(--semi-color-fill-1);
}
.semi-radio-cardRadioGroup_checked {
  background: var(--semi-color-primary-light-default);
  border: 1px solid var(--semi-color-primary);
}
.semi-radio-cardRadioGroup_checked:hover {
  border: 1px solid var(--semi-color-primary-hover);
}
.semi-radio-cardRadioGroup_checked:hover .semi-radio-inner-checked .semi-radio-inner-display {
  border-color: var(--semi-color-primary-hover);
}
.semi-radio-cardRadioGroup_checked:active {
  background: var(--semi-color-primary-light-default);
  border: 1px solid var(--semi-color-primary-active);
}
.semi-radio-cardRadioGroup_checked:active .semi-radio-inner-checked .semi-radio-inner-display {
  border-color: var(--semi-color-primary-active);
}
.semi-radio-cardRadioGroup_checked:active .semi-radio-inner-checked:hover .semi-radio-inner-display {
  background: var(--semi-color-primary-active);
}
.semi-radio-cardRadioGroup_hover {
  background: var(--semi-color-fill-0);
}
.semi-radio-cardRadioGroup_disabled:active {
  background: transparent;
}
.semi-radio-cardRadioGroup_checked_disabled.semi-radio-cardRadioGroup {
  background: var(--semi-color-primary-light-default);
  border: 1px solid var(--semi-color-primary-disabled);
}
.semi-radio-cardRadioGroup_checked_disabled.semi-radio-cardRadioGroup .semi-radio-inner-checked .semi-radio-inner-display {
  border-color: var(--semi-color-primary-disabled);
}
.semi-radio-cardRadioGroup_checked_disabled.semi-radio-cardRadioGroup:hover .semi-radio-inner-checked .semi-radio-inner-display {
  border-color: var(--semi-color-primary-disabled);
}
.semi-radio.semi-radio-disabled:hover .semi-radio-inner-display, .semi-radio.semi-radio-disabled:active .semi-radio-inner-display {
  background: var(--semi-color-disabled-fill);
  border: solid 1px var(--semi-color-border);
}
.semi-radio.semi-radio-disabled:hover .semi-radio-inner-checked .semi-radio-inner-display, .semi-radio.semi-radio-disabled:active .semi-radio-inner-checked .semi-radio-inner-display {
  background: var(--semi-color-primary-disabled);
  border-color: var(--semi-color-primary-disabled);
}
.semi-radio-inner {
  display: inline-flex;
  margin-top: 2px;
  position: relative;
  width: 16px;
  height: 16px;
  vertical-align: sub;
  user-select: none;
}
.semi-radio-inner-display {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border: solid 1px var(--semi-color-text-3);
  border-radius: 16px;
  background: transparent;
  transition: background-color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none), border var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
}
.semi-radio-inner-display .semi-icon {
  width: 100%;
  height: 100%;
  font-size: 14px;
}
.semi-radio-content {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}
.semi-radio:hover .semi-radio-inner-display {
  background: var(--semi-color-fill-0);
}
.semi-radio:active .semi-radio-inner-display {
  background: var(--semi-color-fill-1);
}
.semi-radio-addon {
  user-select: none;
  color: var(--semi-color-text-0);
  display: inline-flex;
  align-items: center;
}
.semi-radio-addon-buttonRadio {
  text-align: center;
  border-radius: var(--semi-border-radius-small);
  font-weight: 600;
  color: var(--semi-color-text-1);
  font-size: 12px;
  padding: 4px 16px;
  transition: background-color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none), border var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
}
.semi-radio-addon-buttonRadio-hover {
  font-weight: 600;
  background: var(--semi-color-fill-1);
}
.semi-radio-addon-buttonRadio-checked {
  font-weight: 600;
  background: var(--semi-color-bg-3);
  color: var(--semi-color-primary);
}
.semi-radio-addon-buttonRadio-disabled {
  cursor: not-allowed;
  color: var(--semi-color-disabled-text);
}
.semi-radio-addon-buttonRadio-small {
  font-size: 12px;
  padding: 2px 16px;
}
.semi-radio-addon-buttonRadio-large {
  font-size: 14px;
  padding: 6px 24px;
}
.semi-radio .semi-radio-inner-checked:hover .semi-radio-inner-display {
  background: var(--semi-color-primary-hover);
}
.semi-radio .semi-radio-inner-checked:active .semi-radio-inner-display {
  background: var(--semi-color-primary-active);
}
.semi-radio .semi-radio-inner-checked .semi-radio-inner-display {
  border: solid 1px var(--semi-color-primary);
  background: var(--semi-color-primary);
  color: rgba(var(--semi-white), 1);
  border-radius: 16px;
}
.semi-radio .semi-radio-inner-checked > .semi-radio-addon {
  color: var(--semi-color-text-0);
}
.semi-radio .semi-radio-inner-buttonRadio,
.semi-radio .semi-radio-inner-pureCardRadio {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
}
.semi-radio-disabled, .semi-radio-disabled:hover {
  cursor: not-allowed;
}
.semi-radio-disabled .semi-radio-inner {
  cursor: not-allowed;
}
.semi-radio-disabled .semi-radio-inner-display {
  opacity: 0.75;
  background: var(--semi-color-disabled-fill);
  border-color: var(--semi-color-border);
}
.semi-radio-disabled .semi-radio-inner-display:hover {
  background: transparent;
}
.semi-radio-disabled .semi-radio-inner-checked .semi-radio-inner-display {
  background: var(--semi-color-primary-disabled);
  border-color: var(--semi-color-primary-disabled);
}
.semi-radio-disabled .semi-radio-inner-checked .semi-radio-inner-display:hover {
  background: var(--semi-color-primary-disabled);
  border-color: var(--semi-color-primary-disabled);
}
.semi-radio-disabled .semi-radio-addon {
  color: var(--semi-color-disabled-text);
}
.semi-radio-disabled .semi-radio-extra {
  color: var(--semi-color-disabled-text);
}
.semi-radio-extra {
  color: var(--semi-color-text-2);
  box-sizing: border-box;
}
.semi-radio-focus {
  outline: 2px solid var(--semi-color-primary-light-active);
}
.semi-radio-focus-border {
  border: solid 1px var(--semi-color-focus-border);
}

.semi-radioGroup {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.semi-radioGroup-vertical {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
.semi-radioGroup-vertical-default .semi-radio {
  display: flex;
}
.semi-radioGroup-vertical-card .semi-radio {
  display: flex;
}
.semi-radioGroup-horizontal {
  display: inline-flex;
  flex-wrap: wrap;
  vertical-align: bottom;
  gap: 16px;
}
.semi-radioGroup-buttonRadio {
  display: inline-block;
  background: var(--semi-color-fill-0);
  border-radius: var(--semi-border-radius-small);
  vertical-align: middle;
}

.semi-rtl .semi-radio,
.semi-portal-rtl .semi-radio {
  direction: rtl;
}
.semi-rtl .semi-radio input[type=checkbox],
.semi-rtl .semi-radio input[type=radio],
.semi-portal-rtl .semi-radio input[type=checkbox],
.semi-portal-rtl .semi-radio input[type=radio] {
  left: auto;
  right: 0;
}
.semi-rtl .semi-radio-buttonRadioGroup:not(:last-child),
.semi-portal-rtl .semi-radio-buttonRadioGroup:not(:last-child) {
  padding-left: 0;
}
.semi-rtl .semi-radioGroup,
.semi-portal-rtl .semi-radioGroup {
  direction: rtl;
}