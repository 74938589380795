/* shadow */
/* sizing */
/* spacing */
.semi-popconfirm {
  box-sizing: border-box;
  max-width: 400px;
}
.semi-popconfirm-inner {
  display: flex;
  flex-direction: column;
  padding: 24px 24px 24px 20px;
  position: relative;
}
.semi-popconfirm-header {
  display: flex;
  justify-content: flex-start;
}
.semi-popconfirm-header-title {
  font-size: 16px;
  line-height: 22px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin-bottom: 8px;
  color: var(--semi-color-text-0);
}
.semi-popconfirm-header-icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}
.semi-popconfirm-header .semi-icon-alert_triangle {
  color: var(--semi-color-warning);
}
.semi-popconfirm-header-body {
  display: inline-flex;
  flex-grow: 1;
  flex-direction: column;
}
.semi-popconfirm-body {
  margin-left: 36px;
  color: var(--semi-color-text-2);
}
.semi-popconfirm-body > p {
  margin: 0;
  padding: 0;
}
.semi-popconfirm-footer {
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
}
.semi-popconfirm-footer > .semi-button:first-child:not(:last-child) {
  margin-right: 8px;
}
.semi-popconfirm-popover {
  border-radius: var(--semi-border-radius-medium);
}

.semi-popover-with-arrow .semi-popconfirm-inner {
  padding: 12px 12px 12px 8px;
}

.semi-popconfirm-rtl {
  direction: rtl;
}
.semi-popconfirm-rtl .semi-popconfirm-inner {
  padding: 24px 20px 24px 24px;
}
.semi-popconfirm-rtl .semi-popconfirm-header {
  margin-right: 0;
}
.semi-popconfirm-rtl .semi-popconfirm-header-icon {
  margin-right: 0;
  margin-left: 12px;
}
.semi-popconfirm-rtl .semi-popconfirm-footer {
  justify-content: flex-end;
}
.semi-popconfirm-rtl .semi-popconfirm-footer > .semi-button:first-child:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}

.semi-popover-with-arrow.semi-popconfirm-rtl {
  direction: rtl;
}
.semi-popover-with-arrow.semi-popconfirm-rtl .semi-popconfirm-inner {
  padding: 12px 8px 12px 12px;
}