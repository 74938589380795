/* shadow */
/* sizing */
/* spacing */
.semi-sidesheet {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  height: 100%;
}
.semi-sidesheet-inner:focus, .semi-sidesheet-content:focus {
  outline: none;
}
.semi-sidesheet-inner-wrap {
  position: absolute;
}
.semi-sidesheet-left, .semi-sidesheet-right {
  top: 0;
  width: 0%;
  height: 100%;
}
.semi-sidesheet-left .semi-sidesheet-inner-wrap, .semi-sidesheet-right .semi-sidesheet-inner-wrap {
  height: 100%;
}
.semi-sidesheet-left.semi-sidesheet, .semi-sidesheet-right.semi-sidesheet {
  width: 100%;
}
.semi-sidesheet-right {
  right: 0;
}
.semi-sidesheet-right .semi-sidesheet-inner-wrap {
  right: 0;
}
.semi-sidesheet-top, .semi-sidesheet-bottom {
  left: 0;
  width: 100%;
  height: 0%;
}
.semi-sidesheet-top .semi-sidesheet-inner-wrap, .semi-sidesheet-bottom .semi-sidesheet-inner-wrap {
  width: 100%;
}
.semi-sidesheet-top.semi-sidesheet, .semi-sidesheet-bottom.semi-sidesheet {
  height: 100%;
}
.semi-sidesheet-top {
  top: 0;
}
.semi-sidesheet-bottom {
  bottom: 0;
}
.semi-sidesheet-bottom .semi-sidesheet-inner-wrap {
  bottom: 0;
}
.semi-sidesheet-title {
  flex: 1 0 auto;
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: var(--semi-color-text-0);
  text-align: left;
}
.semi-sidesheet-inner {
  z-index: 1;
  overflow: auto;
  background-color: var(--semi-color-bg-2);
  border: 0;
}
.semi-sidesheet-header {
  display: flex;
  align-items: flex-start;
  padding: 24px;
  padding-bottom: 20px;
  border-bottom: 0px solid var(--semi-color-border);
}
.semi-sidesheet-body {
  padding: 0 24px;
  flex: 1;
  overflow: auto;
}
.semi-sidesheet-size-small {
  width: 448px;
}
.semi-sidesheet-size-medium {
  width: 684px;
}
.semi-sidesheet-size-large {
  width: 920px;
}
.semi-sidesheet-size-small.semi-sidesheet {
  width: 448px;
}
.semi-sidesheet-size-medium.semi-sidesheet {
  width: 684px;
}
.semi-sidesheet-size-large.semi-sidesheet {
  width: 920px;
}
.semi-sidesheet-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
}
.semi-sidesheet-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--semi-color-overlay-bg);
  opacity: 1;
}
.semi-sidesheet-mask-hidden {
  opacity: 0;
  display: none;
}
.semi-sidesheet-footer {
  padding: 24px;
}
@keyframes semi-sidesheet-slideShow_top {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes semi-sidesheet-slideHide_top {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}
@keyframes semi-sidesheet-slideShow_bottom {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes semi-sidesheet-slideHide_bottom {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}
@keyframes semi-sidesheet-slideShow_left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes semi-sidesheet-slideHide_left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes semi-sidesheet-slideShow_right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes semi-sidesheet-slideHide_right {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
@keyframes semi-sidesheet-opacityShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes semi-sidesheet-opacityHide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.semi-sidesheet-animation-content_show_top {
  animation: semi-sidesheet-slideShow_top 180ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms;
  animation-fill-mode: forwards;
}
.semi-sidesheet-animation-content_hide_top {
  animation: semi-sidesheet-slideHide_top 180ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms;
  animation-fill-mode: forwards;
}
.semi-sidesheet-animation-content_show_bottom {
  animation: semi-sidesheet-slideShow_bottom 180ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms;
  animation-fill-mode: forwards;
}
.semi-sidesheet-animation-content_hide_bottom {
  animation: semi-sidesheet-slideHide_bottom 180ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms;
  animation-fill-mode: forwards;
}
.semi-sidesheet-animation-content_show_left {
  animation: semi-sidesheet-slideShow_left 180ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms;
  animation-fill-mode: forwards;
}
.semi-sidesheet-animation-content_hide_left {
  animation: semi-sidesheet-slideHide_left 180ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms;
  animation-fill-mode: forwards;
}
.semi-sidesheet-animation-content_show_right {
  animation: semi-sidesheet-slideShow_right 180ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms;
  animation-fill-mode: forwards;
}
.semi-sidesheet-animation-content_hide_right {
  animation: semi-sidesheet-slideHide_right 180ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms;
  animation-fill-mode: forwards;
}
.semi-sidesheet-animation-mask_show {
  animation: semi-sidesheet-opacityShow 180ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms;
  animation-fill-mode: forwards;
}
.semi-sidesheet-animation-mask_hide {
  animation: semi-sidesheet-opacityHide 180ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms;
  animation-fill-mode: forwards;
}

.semi-sidesheet-fixed .semi-sidesheet-inner {
  box-shadow: var(--semi-shadow-elevated);
}
.semi-sidesheet-fixed.semi-sidesheet-left {
  left: 0;
}
.semi-sidesheet-fixed.semi-sidesheet-left .semi-sidesheet-inner {
  left: 0;
}
.semi-sidesheet-fixed.semi-sidesheet-right {
  left: auto;
}
.semi-sidesheet-fixed.semi-sidesheet-right .semi-sidesheet-inner {
  right: 0;
}
.semi-sidesheet-fixed.semi-sidesheet-top, .semi-sidesheet-fixed.semi-sidesheet-bottom {
  height: auto;
}
.semi-sidesheet-fixed.semi-sidesheet-bottom {
  top: auto;
}
.semi-sidesheet-fixed.semi-sidesheet-bottom .semi-sidesheet-inner {
  bottom: 0;
}

.semi-sidesheet.semi-sidesheet-popup {
  position: absolute;
}

.semi-sidesheet-hidden {
  display: none;
}

.semi-sidesheet-rtl .semi-sidesheet-inner {
  direction: rtl;
}
.semi-sidesheet-rtl .semi-sidesheet-title {
  text-align: right;
}