/* shadow */
/* sizing */
/* spacing */
.semi-anchor {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.semi-anchor-size-small {
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.semi-anchor-slide {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
.semi-anchor-slide-muted {
  display: none;
}
.semi-anchor-slide-bar {
  display: none;
  position: absolute;
  top: 0;
  width: 2px;
  border-radius: 1px;
}
.semi-anchor-slide-bar-active {
  display: inline-block;
}
.semi-anchor-slide-bar-default {
  height: 28px;
}
.semi-anchor-slide-bar-small {
  height: 24px;
}
.semi-anchor-slide-bar-primary {
  background-color: var(--semi-color-primary);
}
.semi-anchor-slide-bar-tertiary {
  background-color: var(--semi-color-tertiary);
}
.semi-anchor-slide::before {
  position: absolute;
  display: block;
  width: 2px;
  height: 100%;
  background-color: var(--semi-color-border);
  border-radius: 1px;
  content: " ";
}
.semi-anchor-link-title {
  cursor: pointer;
  color: var(--semi-color-text-2);
  padding-top: 4px;
  padding-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 3px;
  transition: color var(--semi-transition_duration-none) var(--semi-transition_function-easeIn) var(--semi-transition_delay-none);
  transform: scale(var(--semi-transform_scale-none));
}
.semi-anchor-link-title:hover {
  color: var(--semi-color-tertiary-hover);
}
.semi-anchor-link-title-active {
  color: var(--semi-color-text-0);
}
.semi-anchor-link-title:focus-visible {
  outline: 2px solid var(--semi-color-primary-light-active);
  outline-offset: -2px;
}
.semi-anchor-link-title-disabled {
  color: var(--semi-color-disabled-text);
  cursor: not-allowed;
}
.semi-anchor-link-title-disabled:hover {
  color: var(--semi-color-disabled-text);
}
.semi-anchor-link-tooltip {
  cursor: pointer;
  color: var(--semi-color-text-2) !important;
}
.semi-anchor-link-tooltip-small {
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.semi-anchor-link-tooltip:hover {
  color: var(--semi-color-tertiary-hover) !important;
}
.semi-anchor-link-tooltip-active {
  color: var(--semi-color-text-0) !important;
}
.semi-anchor-link-tooltip-disabled {
  color: var(--semi-color-disabled-text) !important;
  cursor: not-allowed;
}
.semi-anchor-link-tooltip-disabled:hover {
  color: var(--semi-color-disabled-text) !important;
}

.semi-rtl .semi-anchor,
.semi-portal-rtl .semi-anchor {
  direction: rtl;
}
.semi-rtl .semi-anchor-slide,
.semi-portal-rtl .semi-anchor-slide {
  right: 0;
  left: auto;
}
.semi-rtl .semi-anchor-link,
.semi-portal-rtl .semi-anchor-link {
  padding-left: auto;
}